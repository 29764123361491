import {createSlice} from "@reduxjs/toolkit";

let initialState = {
    progress: 100
}

const timeStatusSlice = createSlice({
    name: 'timeStatusSlice',
    initialState,
    reducers: {
        changeTimeStatus(state, action) {
            state.progress = action.payload;
        },
    },
});

export const {changeTimeStatus} = timeStatusSlice.actions;

export default timeStatusSlice.reducer;