import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const AdminGisService = {};

AdminGisService.createGisProject = async (name) => {
    let data = new FormData();
    data.append("name", name);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/gis-project/create",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

AdminGisService.addUserToProject = async (username, gisProjectId) => {
    let data = new FormData();
    data.append("username", username);

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/gis-project/${gisProjectId}/add-user`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

AdminGisService.removeUserFromProject = async (username, gisProjectId) => {
    let data = new FormData();
    data.append("username", username);

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/gis-project/${gisProjectId}/remove-user`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

AdminGisService.addBuilding = async (gisProjectId, lats, lngs) => {
    let data = new FormData();
    data.append("gisProjectId", gisProjectId);
    data.append("lats", lats);
    data.append("lngs", lngs);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/gis/add/building",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

AdminGisService.addConstructArea = async (gisProjectId, lats, lngs) => {
    let data = new FormData();
    data.append("gisProjectId", gisProjectId);
    data.append("lats", lats);
    data.append("lngs", lngs);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/gis/add/construct-area",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

AdminGisService.addSensorPoint = async (gisProjectId, lat, lng, alpha, name) => {
    let data = new FormData();
    data.append("gisProjectId", gisProjectId);
    data.append("lat", lat);
    data.append("lng", lng);
    data.append("alpha", alpha);
    data.append("name", name);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/gis/add/sensor-point",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

AdminGisService.addMonitoringZone = async (gisProjectId, lats, lngs) => {
    let data = new FormData();
    data.append("gisProjectId", gisProjectId);
    data.append("lats", lats);
    data.append("lngs", lngs);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/gis/add/monitoring-zone",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

AdminGisService.addMonitoringPoint = async (gisProjectId, lat, lng) => {
    let data = new FormData();
    data.append("gisProjectId", gisProjectId);
    data.append("lat", lat);
    data.append("lng", lng);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/gis/add/monitoring-point",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default AdminGisService;