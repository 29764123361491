import React, {useEffect, useState} from 'react';
import {Flex, Image} from "antd";
import Header from "../components/Header/Header";
import BlueButton from "../../components/UI/Buttons/BlueButton";
import DefaultButton from "../../components/UI/Buttons/DefaultButton";
import MyText from "../../components/UI/Text/MyText";
import bannerImg from '../../images/bannerImage.webp';
import Footer from "../components/Footer/Footer";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import aida from '../../images/team/aida.png';
import aigul from '../../images/team/aigul.png';
import aleksej from '../../images/team/aleksej.jpg';
import alisher from '../../images/team/alisher.png';
import assel from '../../images/team/assel.png';
import balgaisha from '../../images/team/balgaisha.jpg';
import elbek from '../../images/team/elbek.jpg';
import shyngys from '../../images/team/shyngys.jpg';
import sungat from '../../images/team/sungat.jpg';

const HomePage = () => {

    return (
        <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"} justify={"flex-start"} gap={50}>
            <Header/>
            <Flex style={{width: "100%", maxWidth: 1440}} vertical align={"center"} justify={"flex-start"} gap={50}>
                <Banner/>
                <InfoItem id={'aboutUs'} header={<IntlMessage id={'homePage.projectInfo.h1'}/>}
                          body={<IntlMessage id={'homePage.projectInfo.d1'}/>}/>
                <InfoItem header={<IntlMessage id={'homePage.projectInfo.h2'}/>}
                          body={<IntlMessage id={'homePage.projectInfo.d2'}/>}/>
                <InfoItem header={<IntlMessage id={'homePage.projectInfo.h3'}/>}
                          body={<IntlMessage id={'homePage.projectInfo.d3'}/>}/>
                <InfoItem header={<IntlMessage id={'homePage.projectInfo.h4'}/>}
                          body={<IntlMessage id={'homePage.projectInfo.d4'}/>}/>
                <InfoItem header={<IntlMessage id={'homePage.projectInfo.h5'}/>}
                          body={<IntlMessage id={'homePage.projectInfo.d5'}/>}/>
            </Flex>
            <Flex style={{width: "100%", maxWidth: 1440}} vertical align={"center"} justify={"center"} gap={50}>
                <MyText strong size={"large"}>
                    <IntlMessage id={'homePage.projectInfo.team'}/>
                </MyText>
                <Flex style={{width: "100%"}} wrap={"wrap"} align={"center"} justify={"center"} gap={100}>
                    <TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.elbek'}/>} imageSrc={elbek}/>
                    <TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.assel'}/>} imageSrc={assel}/>
                    {/*<TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.aigul'}/>} imageSrc={aigul}/>*/}
                    <TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.balgaisha'}/>} imageSrc={balgaisha}/>
                    <TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.aida'}/>} imageSrc={aida}/>
                    <TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.sungat'}/>} imageSrc={sungat}/>
                    <TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.shyngys'}/>} imageSrc={shyngys}/>
                    <TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.alisher'}/>} imageSrc={alisher}/>
                    <TeamItem fullName={<IntlMessage id={'homePage.projectInfo.team.aleksej'}/>} imageSrc={aleksej}/>
                </Flex>
            </Flex>

            <Footer/>
        </Flex>
    );
};

const Banner = () => {
    return (
        <Flex style={{width: "100%", height: 500, borderRadius: 15, overflow: "hidden", position: "relative"}} align={"center"} justify={"center"}>
            <Image src={bannerImg} preview={false} style={{width: "100%", height: "100%"}} wrapperStyle={{position: "absolute"}}/>
            <div style={{backgroundColor: "#000d1f", opacity: 0.2, width: "100%", height: "100%", position: "absolute"}}></div>
        </Flex>
    )
}

const TeamItem = ({fullName, imageSrc}) => {
    return (
        <Flex style={{width: 200}} vertical gap={15} align={"center"} justify={"center"}>
            <Image preview={false} style={{width: 200, borderRadius: 8}} src={imageSrc}/>
            <MyText size={"small"} italic style={{textAlign: "center"}}>
                {fullName}
            </MyText>
        </Flex>
    )
}

const InfoItem = ({header, body, id}) => {
    return (
        <Flex id={id} style={{width: "100%"}} vertical gap={25} align={"flex-start"} justify={"flex-start"}>
            <MyText strong size={"large"}>
                {header}
            </MyText>
            <MyText>
                {body}
            </MyText>
        </Flex>
    )
}
export default HomePage;