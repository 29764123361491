import React from 'react';
import classes from "./footer.module.css";
import {Flex} from "antd";
import MyText from "../../../components/UI/Text/MyText";
import {clrs} from "../../../constants/colors";

const Footer = () => {
    return (
        <Flex style={{width: "100%", padding: 35}} className={classes.footer} vertical align={"center"} justify={"center"}>
            <Flex style={{width: "100%", maxWidth: 1440}} className={classes.footerInner} vertical gap={40} align={"center"} justify={"center"}>
                {/*<Flex style={{width: "100%"}} wrap={"wrap"} gap={50} align={"center"} justify={"center"}>*/}
                {/*    <MyText style={{color: clrs.grey}}>*/}
                {/*        Главная*/}
                {/*    </MyText>*/}
                {/*    <MyText style={{color: clrs.grey}}>*/}
                {/*        Главная*/}
                {/*    </MyText>*/}
                {/*    <MyText style={{color: clrs.grey}}>*/}
                {/*        Главная*/}
                {/*    </MyText>*/}
                {/*</Flex>*/}
                <MyText style={{color: clrs.grey}}>
                    2024 SVAM KZ
                </MyText>
            </Flex>
        </Flex>
    );
};

export default Footer;