import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Flex, Form, Input, message, Modal, Tooltip} from "antd";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import BlueButton from "../../components/UI/Buttons/BlueButton";
import {FieldTimeOutlined, PlusOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons";
import MyText from "../../components/UI/Text/MyText";
import {Link, useNavigate} from "react-router-dom";
import {clrs} from "../../constants/colors";
import AuthService from "../../services/AuthService";
import {getUserByToken} from "../../store/slices/userSlice";
import AdminGisService from "../../services/AdminGisService";
import classes from "../global_styles.module.css";
import FormItem from "antd/es/form/FormItem";
import GisService from "../../services/GisService";

const MyProjectsPage = () => {
    const {user, isLoading} = useSelector(state => state.user);
    const navigate = useNavigate();
    const [openCreate, setOpenCreate] = useState(false);
    const [form] = Form.useForm();
    const name = Form.useWatch('name', form);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        GisService.getMy()
            .then((r) => {
                setProjects(r.data);
            })
            .catch(() => {

            })
    }, []);


    if (!user) {
        navigate('/login')
    }

    function handleCreateProject() {
        form.validateFields()
            .then(() => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                AdminGisService.createGisProject(name)
                    .then((result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        setOpenCreate(false);
                        message.loading(<IntlMessage id={'loading'}/>, 0);
                        GisService.getMy()
                            .then((r) => {
                                message.destroy();
                                setProjects(r.data);
                            })
                            .catch(() => {
                                message.destroy();
                            })
                    })
                    .catch((result) => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
            })
            .catch(() => {

            })
    }

    return (
        <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"} justify={"flex-start"} gap={50}>
            <Header/>
            <Flex style={{width: "100%", maxWidth: 1440, minHeight: "60vh"}} vertical align={"flex-start"} justify={"flex-start"} gap={50}>
                <Flex style={{
                    width: "100%",
                    position: "relative",
                    padding: 50,
                    borderRadius: 15,
                    background: "linear-gradient(180deg, rgba(0,89,255,1) 0%, rgba(76,162,247,1) 100%)"
                }}>
                    <MyText strong size={"large"} uppercase style={{color: "white"}}>
                        <IntlMessage id={'myProjects'}/>
                    </MyText>
                </Flex>
                {
                    user?.username === 'admin' &&
                    <Flex style={{width: "100%", position: "relative"}}>
                        <BlueButton onClick={() => {setOpenCreate(true)}}>
                            <PlusOutlined />
                            <span> </span>
                            <IntlMessage id={'myProjects.createProject'}/>
                        </BlueButton>
                    </Flex>
                }
                <Flex style={{width: "100%"}} wrap={"wrap"} gap={35} align={"flex-start"} justify={"flex-start"}>
                    {
                        projects.map((project, i) => {
                            return (
                                <ProjectItem key={new Date() + i} gisProject={project}/>
                            )
                        })
                    }
                </Flex>
            </Flex>
            <Footer/>

            <Modal title={<IntlMessage id={'myProjects.creationProject'}/>} open={openCreate}
                   onOk={handleCreateProject}
                   onCancel={() => {setOpenCreate(false)}}
                   okText={<IntlMessage id={'create'}/>}
                   cancelText={<IntlMessage id={'close'}/>}
            >
                <Form
                    form={form}
                    className={classes.form}
                >
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'name'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="name">
                        <Input rootClassName={classes.formInput}/>
                    </FormItem>
                </Form>
            </Modal>
        </Flex>
    );
};

const ProjectItem = ({gisProject}) => {
    return (
        <Link to={'/gis-project/' + gisProject.id} style={{color: "black", width: "100%", maxWidth: 300, display: "block"}}>
            <Flex style={{width: "100%", maxWidth: 300, height: 140, padding: 20, cursor: "pointer", boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)", borderRadius: 15}} gap={15} vertical align={"flex-start"} justify={"flex-start"}>
                <MyText strong style={{fontSize: "1.3rem"}}>
                    {gisProject.name}
                </MyText>
                <Tooltip title={<IntlMessage id={'createdAt'}/>}>
                    <MyText size={"small"} style={{color: clrs.whiterBlacker}}>
                        <FieldTimeOutlined />
                        <span> </span>
                        {new Date(gisProject.createdAt).toLocaleDateString()}
                    </MyText>
                </Tooltip>
                <Tooltip title={<IntlMessage id={'usersSize'}/>}>
                    <MyText size={"small"}>
                        <TeamOutlined />
                        <span> </span>
                        {gisProject.users.length}
                    </MyText>
                </Tooltip>

            </Flex>
        </Link>

    )
}

export default MyProjectsPage;