import React, {useState} from 'react';
import {Typography} from "antd";
import PropTypes from "prop-types";

const MyText = ({ uppercase, strong, italic, size, hoverable, children, ...rest}) => {
    const style = {
        ...(uppercase && { textTransform: "uppercase" }),
        ...(strong && { fontWeight: "700" }),
        ...(italic && { fontStyle: "italic" }),
        ...(size === "small" && { fontSize: "0.85rem" }),
        ...(size === "default" && { fontSize: "1rem" }),
        ...(size === "large" && { fontSize: "1.5rem" }),
        ...(!size && { fontSize: "1rem" }),
        ...rest.style
    };

    const [isHovered, setIsHovered] = useState(false);

    const baseStyle = {
        position: 'relative',
    };

    const afterStyle = {
        content: '""',
        position: 'absolute',
        width: isHovered ? '100%' : '0',
        bottom: '-7px',
        left: '0',
        height: strong ? '3px' : '2px',
        backgroundColor: 'black',
        transition: '0.4s',
    };

    if (hoverable) {
        return (
            <p {...rest} style={{...style, ...baseStyle}}
               onMouseEnter={() => setIsHovered(true)}
               onMouseLeave={() => setIsHovered(false)}>
                {children}
                <span style={afterStyle}></span>
            </p>
        );
    }

    return (
        <p {...rest} style={style}>
            {children}
        </p>
    );
};
MyText.propTypes = {
    ...Typography.Text.propTypes,
    uppercase: PropTypes.bool,
    strong: PropTypes.bool,
    italic: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    children: PropTypes.node,
};

export default MyText;