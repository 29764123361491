import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const GisService = {};

GisService.getMy = async () => {
    return axios({
        method: "get",
        url: API_BASE_URL + "/api/gis/get/my",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

GisService.getById = async (gisProjectId) => {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/gis/get/${gisProjectId}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

GisService.getInterpolationData = async (gisProjectId, startDate, endDate) => {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/gis/get/interpolation/data`,
        params: {
            gisProjectId,
            startDate: startDate,
            endDate: endDate
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

GisService.getInterpolationDataVibration = async (gisProjectId, startDate, endDate) => {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/gis/get/interpolation/data/vibration`,
        params: {
            gisProjectId,
            startDate: startDate,
            endDate: endDate
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default GisService;