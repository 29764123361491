import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const AdminUserService = {};

AdminUserService.searchUsers = async (username) => {

    return axios({
        method: "get",
        url: API_BASE_URL + "/api/admin/user/search",
        params: {
            username,
            page: 0,
            limit: 10000
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default AdminUserService;