import React, {useEffect, useRef, useState} from 'react';
import {
    MapContainer,
    TileLayer,
    LayersControl,
    Marker,
    Polygon,
    Polyline,
    Rectangle,
    Circle,
    useMapEvents,
    useMap
} from 'react-leaflet';
import * as d3 from 'd3';
import L, { latLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Flex, Button } from "antd";
import { clrs } from "../../constants/colors"; // Assuming clrs contains the whiterBlacker color

// Fix for Leaflet's default icon not showing correctly
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.6/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png',
});

const STICKING_DISTANCE = 5;  // Reduced sticking distance

const { BaseLayer } = LayersControl;

const PointAdder = ({ onAddPoint, adding, polygons, lines, rectangles, squares }) => {
    const [tempPoint, setTempPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                onAddPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                setTempPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
            }
        }
    });

    return (
        <>
            {tempPoint && adding && (
                <Marker position={tempPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
        </>
    );
};

const PolygonAdder = ({ onAddPolygon, adding, polygons, lines, rectangles, squares }) => {
    const [polygon, setPolygon] = useState([]);
    const [tempPoint, setTempPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                if (polygon.length > 0 && latLng(polygon[0]).distanceTo(e.latlng) < STICKING_DISTANCE) {
                    onAddPolygon([...polygon, polygon[0]]);
                    setPolygon([]);
                    setTempPoint(null);
                } else {
                    setPolygon([...polygon, closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng]);
                }
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                if (polygon.length > 0 && latLng(polygon[0]).distanceTo(e.latlng) < STICKING_DISTANCE) {
                    setTempPoint(polygon[0]);
                } else {
                    setTempPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
                }
            }
        }
    });

    return (
        <>
            {polygon.length > 0 && (
                <Polyline positions={[...polygon, ...(tempPoint ? [tempPoint] : [])]} pathOptions={{ color: 'yellow', dashArray: '5, 5' }} />
            )}
            {tempPoint && adding && (
                <Marker position={tempPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
        </>
    );
};

const LineAdder = ({ onAddLine, adding, polygons, lines, rectangles, squares }) => {
    const [line, setLine] = useState([]);
    const [tempPoint, setTempPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                if (line.length === 1) {
                    onAddLine([...line, closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng]);
                    setLine([]);
                    setTempPoint(null);
                } else {
                    setLine([closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng]);
                }
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                setTempPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
            }
        }
    });

    return (
        <>
            {line.length > 0 && (
                <Polyline positions={[...line, ...(tempPoint ? [tempPoint] : [])]} pathOptions={{ color: 'yellow', dashArray: '5, 5' }} />
            )}
            {tempPoint && adding && (
                <Marker position={tempPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
        </>
    );
};

const RectangleAdder = ({ onAddRectangle, adding, polygons, lines, rectangles, squares }) => {
    const [rectCorners, setRectCorners] = useState([]);
    const [tempPoint, setTempPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                if (rectCorners.length === 1) {
                    onAddRectangle([rectCorners[0], closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng]);
                    setRectCorners([]);
                    setTempPoint(null);
                } else {
                    setRectCorners([closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng]);
                }
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                setTempPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
            }
        }
    });

    return (
        <>
            {rectCorners.length === 1 && tempPoint && (
                <Rectangle bounds={[rectCorners[0], tempPoint]} pathOptions={{ color: 'yellow', dashArray: '5, 5' }} />
            )}
            {tempPoint && adding && (
                <Marker position={tempPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
        </>
    );
};

const SquareAdder = ({ onAddSquare, adding, polygons, lines, rectangles, squares }) => {
    const [tempSquare, setTempSquare] = useState(null);
    const [startingPoint, setStartingPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                const center = closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng;
                const corners = getSquareCorners(center, 500, polygons, lines, rectangles, squares); // Size is now 50 units
                onAddSquare(corners);
                setTempSquare(null);
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                const center = closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng;
                const corners = getSquareCorners(center, 500, polygons, lines, rectangles, squares); // Size is now 50 units
                setTempSquare(corners);
                setStartingPoint(center);
            }
        }
    });

    return (
        <>
            {startingPoint && adding && (
                <Marker position={startingPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
            {tempSquare && adding && (
                <>
                    <Polygon positions={tempSquare} pathOptions={{ color: 'yellow', dashArray: '5, 5' }} />
                    {drawGrid(tempSquare)}
                </>
            )}
        </>
    );
};

const calculateGridMatrix = (corners) => {
    const [topLeft, topRight, bottomRight, bottomLeft] = corners;
    const gridMatrix = [];

    const numDivisions = 100; // 100x100 grid
    const latStep = (topLeft.lat - bottomLeft.lat) / numDivisions;
    const lngStep = (topRight.lng - topLeft.lng) / numDivisions;

    // Generate five random centers
    const generateRandomCenter = () => ({
        lat: topLeft.lat - Math.random() * (topLeft.lat - bottomLeft.lat),
        lng: topLeft.lng + Math.random() * (topRight.lng - topLeft.lng)
    });

    const randomCenters = [
        generateRandomCenter(),
        generateRandomCenter(),
        generateRandomCenter(),
        generateRandomCenter(),
        generateRandomCenter()
    ];

    // Function to calculate distance between two points on the Earth's surface
    const calculateDistance = (point1, point2) => {
        const R = 6371e3; // Radius of the Earth in meters
        const lat1 = point1.lat * Math.PI / 180;
        const lat2 = point2.lat * Math.PI / 180;
        const deltaLat = (point2.lat - point1.lat) * Math.PI / 180;
        const deltaLng = (point2.lng - point1.lng) * Math.PI / 180;

        const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    };

    // Gaussian Kriging algorithm to calculate weights
    const gaussianKrigingWeights = (distances) => {
        const weights = distances.map(distance => 1 / (distance + 1e-6)); // Add a small value to avoid division by zero
        const sumWeights = weights.reduce((acc, weight) => acc + weight, 0);
        return weights.map(weight => weight / sumWeights);
    };

    for (let i = 0; i < numDivisions; i++) {
        for (let j = 0; j < numDivisions; j++) {
            const squareCenter = {
                lat: topLeft.lat - i * latStep,
                lng: topLeft.lng + j * lngStep
            };

            // Calculate the distances from the random centers
            const distancesToCenters = randomCenters.map(center =>
                calculateDistance(center, squareCenter)
            );

            // Calculate weights using the Gaussian Kriging algorithm
            const weights = gaussianKrigingWeights(distancesToCenters);

            // Calculate the weighted average distance
            const weightedDistance = weights.reduce((acc, weight, index) => acc + weight * distancesToCenters[index], 0);

            // Normalize the weighted distance to a value between 0 and 1
            const maxDistance = calculateDistance(topLeft, bottomRight);
            const normalizedDistance = weightedDistance / maxDistance;

            gridMatrix.push(normalizedDistance);
        }
    }

    console.table(gridMatrix);
    return gridMatrix;
};
const drawGrid = (corners) => {
    calculateGridMatrix(corners)
    const [topLeft, topRight, bottomRight, bottomLeft] = corners;
    const gridSquares = [];
    const centerMarkers = [];

    const numDivisions = 100; // 100x100 grid
    const latStep = (topLeft.lat - bottomLeft.lat) / numDivisions;
    const lngStep = (topRight.lng - topLeft.lng) / numDivisions;

    // Generate five random centers for the radial gradients
    const generateRandomCenter = () => ({
        lat: topLeft.lat - Math.random() * (topLeft.lat - bottomLeft.lat),
        lng: topLeft.lng + Math.random() * (topRight.lng - topLeft.lng),
    });

    const randomCenters = [
        generateRandomCenter(),
        generateRandomCenter(),
        generateRandomCenter(),
        generateRandomCenter(),
        generateRandomCenter()
    ];

    // Add markers for the center points
    randomCenters.forEach(center => centerMarkers.push(center));

    // Function to calculate distance between two points on the Earth's surface
    const calculateDistance = (point1, point2) => {
        const R = 6371e3; // Radius of the Earth in meters
        const lat1 = point1.lat * Math.PI / 180;
        const lat2 = point2.lat * Math.PI / 180;
        const deltaLat = (point2.lat - point1.lat) * Math.PI / 180;
        const deltaLng = (point2.lng - point1.lng) * Math.PI / 180;

        const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    };

    // Simple Kriging algorithm to calculate weights
    const krigingWeights = (distances) => {
        const weights = distances.map(distance => 1 / (distance + 1e-6)); // Add a small value to avoid division by zero
        const sumWeights = weights.reduce((acc, weight) => acc + weight, 0);
        return weights.map(weight => weight / sumWeights);
    };

    for (let i = 0; i < numDivisions; i++) {
        for (let j = 0; j < numDivisions; j++) {
            const squareTopLeft = {
                lat: topLeft.lat - i * latStep,
                lng: topLeft.lng + j * lngStep
            };
            const squareTopRight = {
                lat: squareTopLeft.lat,
                lng: squareTopLeft.lng + lngStep
            };
            const squareBottomRight = {
                lat: squareTopLeft.lat - latStep,
                lng: squareTopRight.lng
            };
            const squareBottomLeft = {
                lat: squareBottomRight.lat,
                lng: squareTopLeft.lng
            };

            // Calculate the center of the mini-square
            const squareCenter = {
                lat: (squareTopLeft.lat + squareBottomRight.lat) / 2,
                lng: (squareTopLeft.lng + squareBottomRight.lng) / 2
            };

            // Calculate the distances from the random centers
            const distancesToCenters = randomCenters.map(center =>
                calculateDistance(center, squareCenter)
            );

            // Calculate weights using the Kriging algorithm
            const weights = krigingWeights(distancesToCenters);

            // Calculate the weighted average distance
            const weightedDistance = weights.reduce((acc, weight, index) => acc + weight * distancesToCenters[index], 0);

            // Normalize the weighted distance to a value between 0 and 1
            const maxDistance = calculateDistance(topLeft, bottomRight);
            const normalizedDistance = weightedDistance / maxDistance;

            // Calculate the fill color based on the normalized distance (using red, white, blue color scheme)
            let r, g, b;
            if (normalizedDistance < 0.5) {
                r = 255;
                g = Math.floor(255 * (1 - normalizedDistance * 2));
                b = Math.floor(255 * normalizedDistance * 2);
            } else {
                r = Math.floor(255 * (1 - (normalizedDistance - 0.5) * 2));
                g = Math.floor(255 * (1 - (normalizedDistance - 0.5) * 2));
                b = 255;
            }

            const fillColor = `rgba(${r}, ${g}, ${b}, 0.5)`;

            gridSquares.push([
                squareTopLeft,
                squareTopRight,
                squareBottomRight,
                squareBottomLeft,
                fillColor
            ]);
        }
    }

    const polygons = gridSquares.map((square, idx) => (
        <Polygon
            key={idx}
            positions={square.slice(0, 4)}
            pathOptions={{
                color: 'none',
                fillColor: square[4],
                fillOpacity: 1,
                weight: 0
            }}
        />
    ));

    const markers = centerMarkers.map((center, idx) => (
        <Marker
            key={`center-${idx}`}
            position={center}
            icon={L.divIcon({
                className: 'custom-marker',
                html: '<div class="circle center-point"></div>',
                iconSize: [10, 10]
            })}
        />
    ));

    return (
        <>
            {polygons}
            {markers}
        </>
    );
};


// const drawGrid = (corners) => {
//     const [topLeft, topRight, bottomRight, bottomLeft] = corners;
//     const gridLines = [];
//
//     const numDivisions = 200;
//     const latStep = (topLeft.lat - bottomLeft.lat) / numDivisions;
//     const lngStep = (topRight.lng - topLeft.lng) / numDivisions;
//
//     for (let i = 1; i < numDivisions; i++) {
//         // Horizontal lines
//         gridLines.push([
//             { lat: topLeft.lat - i * latStep, lng: topLeft.lng },
//             { lat: topRight.lat - i * latStep, lng: topRight.lng }
//         ]);
//
//         // Vertical lines
//         gridLines.push([
//             { lat: topLeft.lat, lng: topLeft.lng + i * lngStep },
//             { lat: bottomLeft.lat, lng: bottomLeft.lng + i * lngStep }
//         ]);
//     }
//
//     return gridLines.map((line, idx) => (
//         <Polyline key={idx} positions={line} pathOptions={{ color: 'red', weight: 0.2 }} />
//     ));
// };


const getSquareCorners = (center, size) => {
    const halfSize = size / 2;
    const latAdjustment = halfSize / 111320; // Convert meters to degrees latitude
    const lngAdjustment = halfSize / (40075000 * Math.cos(center.lat * Math.PI / 180) / 360); // Convert meters to degrees longitude based on latitude

    return [
        {lat: center.lat + latAdjustment, lng: center.lng - lngAdjustment},
        {lat: center.lat + latAdjustment, lng: center.lng + lngAdjustment},
        {lat: center.lat - latAdjustment, lng: center.lng + lngAdjustment},
        {lat: center.lat - latAdjustment, lng: center.lng - lngAdjustment},
    ];
};

const getClosestPoint = (polygons, lines, rectangles, squares, latlng) => {
    let closestPoint = { point: null, distance: Infinity };

    const checkClosestPoint = (point) => {
        const distance = latLng(point).distanceTo(latlng);
        if (distance < closestPoint.distance) {
            closestPoint = { point, distance };
        }
    };

    const checkClosestEdge = (points) => {
        points.forEach((point, index) => {
            const nextIndex = (index + 1) % points.length;
            const nextPoint = points[nextIndex];
            const edgePoint = getClosestPointOnEdge(point, nextPoint, latlng);
            const edgeDistance = latLng(edgePoint).distanceTo(latlng);
            if (edgeDistance < closestPoint.distance) {
                closestPoint = { point: edgePoint, distance: edgeDistance };
            }
        });
    };

    polygons.forEach(polygon => {
        polygon.forEach(checkClosestPoint);
        checkClosestEdge(polygon);
    });

    lines.forEach(line => {
        line.forEach(checkClosestPoint);
        checkClosestEdge(line);
    });

    rectangles.forEach(rectangle => {
        const bounds = [latLng(rectangle[0]), latLng(rectangle[1])];
        const corners = [
            bounds[0],
            latLng(bounds[0].lat, bounds[1].lng),
            bounds[1],
            latLng(bounds[1].lat, bounds[0].lng),
        ];
        corners.forEach(checkClosestPoint);
        checkClosestEdge(corners);
    });

    squares.forEach(square => {
        square.forEach(checkClosestPoint);
        checkClosestEdge(square);
    });

    return closestPoint;
};

const getClosestPointOnEdge = (start, end, latlng) => {
    const startLatLng = latLng(start);
    const endLatLng = latLng(end);

    const dX = endLatLng.lng - startLatLng.lng;
    const dY = endLatLng.lat - startLatLng.lat;

    if (dX === 0 && dY === 0) {
        return startLatLng;
    }

    const t = ((latlng.lng - startLatLng.lng) * dX + (latlng.lat - startLatLng.lat) * dY) / (dX * dX + dY * dY);

    if (t < 0) {
        return startLatLng;
    } else if (t > 1) {
        return endLatLng;
    }

    return latLng(startLatLng.lat + t * dY, startLatLng.lng + t * dX);
};

const CircleAdder = ({ onAddCircle, adding, polygons, lines, rectangles, squares }) => {
    const [center, setCenter] = useState(null);
    const [radiusPoint, setRadiusPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                if (!center) {
                    const closestPoint = getClosestPoint(polygons, lines, rectangles, squares, e.latlng);
                    setCenter(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
                } else {
                    const distance = center.distanceTo(e.latlng);
                    onAddCircle({ center, radius: distance });
                    setCenter(null);
                    setRadiusPoint(null);
                }
            }
        },
        mousemove(e) {
            if (adding && center) {
                setRadiusPoint(e.latlng);
            }
        }
    });

    return (
        <>
            {center && (
                <Marker position={center} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
            {center && radiusPoint && (
                <Circle center={center} radius={center.distanceTo(radiusPoint)} pathOptions={{ color: 'yellow', dashArray: '5, 5' }} />
            )}
        </>
    );
};


const MonitoringPage = () => {
    const [points, setPoints] = useState(() => {
        const savedPoints = localStorage.getItem('mapPoints');
        return savedPoints ? JSON.parse(savedPoints) : [];
    });

    const [polygons, setPolygons] = useState(() => {
        const savedPolygons = localStorage.getItem('mapPolygons');
        return savedPolygons ? JSON.parse(savedPolygons) : [];
    });

    const [lines, setLines] = useState(() => {
        const savedLines = localStorage.getItem('mapLines');
        return savedLines ? JSON.parse(savedLines) : [];
    });

    const [rectangles, setRectangles] = useState(() => {
        const savedRectangles = localStorage.getItem('mapRectangles');
        return savedRectangles ? JSON.parse(savedRectangles) : [];
    });

    const [squares, setSquares] = useState(() => {
        const savedSquares = localStorage.getItem('mapSquares');
        return savedSquares ? JSON.parse(savedSquares) : [];
    });

    const [circles, setCircles] = useState(() => {
        const savedCircles = localStorage.getItem('mapCircles');
        return savedCircles ? JSON.parse(savedCircles) : [];
    });

    const [addingPoint, setAddingPoint] = useState(false);
    const [addingPolygon, setAddingPolygon] = useState(false);
    const [addingLine, setAddingLine] = useState(false);
    const [addingRectangle, setAddingRectangle] = useState(false);
    const [addingSquare, setAddingSquare] = useState(false);
    const [addingCircle, setAddingCircle] = useState(false);

    useEffect(() => {
        localStorage.setItem('mapPoints', JSON.stringify(points));
    }, [points]);

    useEffect(() => {
        localStorage.setItem('mapPolygons', JSON.stringify(polygons));
    }, [polygons]);

    useEffect(() => {
        localStorage.setItem('mapLines', JSON.stringify(lines));
    }, [lines]);

    useEffect(() => {
        localStorage.setItem('mapRectangles', JSON.stringify(rectangles));
    }, [rectangles]);

    useEffect(() => {
        localStorage.setItem('mapSquares', JSON.stringify(squares));
    }, [squares]);

    useEffect(() => {
        localStorage.setItem('mapCircles', JSON.stringify(circles));
    }, [circles]);

    useEffect(() => {
        var point1 = L.latLng(51.10926133918461, 71.4255934953144); // Example: London
        var point2 = L.latLng(51.10926133918461, 71.4256471394947); // Example: Paris

// Calculate the distance between the two points
        var distance = point1.distanceTo(point2);

// Output the distance in meters
        console.log('Distance between points:', distance, 'meters');
    }, []);

    const handleAddPoint = (latlng) => {
        setPoints([...points, latlng]);
        setAddingPoint(false);
    };

    const handleAddPolygon = (polygon) => {
        setPolygons([...polygons, polygon]);
        setAddingPolygon(false);
    };

    const handleAddLine = (line) => {
        setLines([...lines, line]);
        setAddingLine(false);
    };

    const handleAddRectangle = (rectangle) => {
        setRectangles([...rectangles, rectangle]);
        setAddingRectangle(false);
    };

    const handleAddSquare = (square) => {
        setSquares([...squares, square]);
        setAddingSquare(false);
    };

    const handleAddCircle = (circle) => {
        setCircles([...circles, circle]);
        setAddingCircle(false);
    };

    const handleAddPointClick = () => {
        setAddingPoint(true);
        setAddingPolygon(false);
        setAddingLine(false);
        setAddingRectangle(false);
        setAddingSquare(false);
        setAddingCircle(false);
        alert('Click on the map to add a point.');
    };

    const handleAddPolygonClick = () => {
        setAddingPoint(false);
        setAddingPolygon(true);
        setAddingLine(false);
        setAddingRectangle(false);
        setAddingSquare(false);
        setAddingCircle(false);
        alert('Click on the map to start drawing a polygon. Click near the first point to close the polygon.');
    };

    const handleAddLineClick = () => {
        setAddingPoint(false);
        setAddingPolygon(false);
        setAddingLine(true);
        setAddingRectangle(false);
        setAddingSquare(false);
        setAddingCircle(false);
        alert('Click on the map to start drawing a line. Click to add the first point, and click again to add the second point.');
    };

    const handleAddRectangleClick = () => {
        setAddingPoint(false);
        setAddingPolygon(false);
        setAddingLine(false);
        setAddingRectangle(true);
        setAddingSquare(false);
        setAddingCircle(false);
        alert('Click on the map to start drawing a rectangle. Click to add the first corner, and click again to add the opposite corner.');
    };

    const handleAddSquareClick = () => {
        setAddingPoint(false);
        setAddingPolygon(false);
        setAddingLine(false);
        setAddingRectangle(false);
        setAddingSquare(true);
        setAddingCircle(false);
        alert('Click on the map to add a square. This point will be the center of the square.');
    };

    const handleAddCircleClick = () => {
        setAddingPoint(false);
        setAddingPolygon(false);
        setAddingLine(false);
        setAddingRectangle(false);
        setAddingSquare(false);
        setAddingCircle(true);
        alert('Click on the map to choose the center of the circle. Click again to set the radius.');
    };

    useEffect(() => {
        const svgNamespace = "http://www.w3.org/2000/svg";
        const svg = document.createElementNS(svgNamespace, "svg");
        svg.setAttribute("style", "height: 0; width: 0; position: absolute;");
        const defs = document.createElementNS(svgNamespace, "defs");

        const radialGradient = document.createElementNS(svgNamespace, "radialGradient");
        radialGradient.setAttribute("id", "radialGradient");
        radialGradient.setAttribute("cx", "50%");
        radialGradient.setAttribute("cy", "50%");
        radialGradient.setAttribute("r", "50%");
        radialGradient.setAttribute("fx", "50%");
        radialGradient.setAttribute("fy", "50%");

        const stop1 = document.createElementNS(svgNamespace, "stop");
        stop1.setAttribute("offset", "0%");
        stop1.setAttribute("style", "stop-color: red; stop-opacity: 1");

        const stop2 = document.createElementNS(svgNamespace, "stop");
        stop2.setAttribute("offset", "50%");
        stop2.setAttribute("style", "stop-color: yellow; stop-opacity: 1");

        const stop3 = document.createElementNS(svgNamespace, "stop");
        stop3.setAttribute("offset", "100%");
        stop3.setAttribute("style", `stop-color: ${clrs.whiterGreen}; stop-opacity: 1`);

        radialGradient.appendChild(stop1);
        radialGradient.appendChild(stop2);
        radialGradient.appendChild(stop3);
        defs.appendChild(radialGradient);
        svg.appendChild(defs);
        document.body.appendChild(svg);
    }, []);

    useEffect(() => {
        const svgNamespace = "http://www.w3.org/2000/svg";
        const svg = document.createElementNS(svgNamespace, "svg");
        svg.setAttribute("style", "height: 0; width: 0; position: absolute;");
        const defs = document.createElementNS(svgNamespace, "defs");

        const filter = document.createElementNS(svgNamespace, "filter");
        filter.setAttribute("id", "blurFilter");
        filter.setAttribute("x", "-50%");
        filter.setAttribute("y", "-50%");
        filter.setAttribute("width", "300%");
        filter.setAttribute("height", "300%");

        const feGaussianBlur = document.createElementNS(svgNamespace, "feGaussianBlur");
        feGaussianBlur.setAttribute("in", "SourceGraphic");
        feGaussianBlur.setAttribute("stdDeviation", "20");

        filter.appendChild(feGaussianBlur);
        defs.appendChild(filter);
        svg.appendChild(defs);
        document.body.appendChild(svg);
    }, []);

    useEffect(() => {
        const applyBlurToPolygons = () => {
            setTimeout(() => {
                d3.selectAll('.my-circle').attr('filter', 'url(#blurFilter)');
            }, 100)
        };
        applyBlurToPolygons();
    }, [polygons, lines, rectangles, squares, circles]);

    return (
        <Flex style={{ width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15 }} vertical align={"center"} justify={"flex-start"} gap={50}>
            <Header />
            <Flex justify={"center"} wrap={"wrap"} style={{ gap: '10px', marginBottom: '10px' }}>
                <Button type="primary" onClick={handleAddPointClick}>
                    Add Point
                </Button>
                <Button type="primary" onClick={handleAddPolygonClick}>
                    Add Polygon
                </Button>
                <Button type="primary" onClick={handleAddLineClick}>
                    Add Line
                </Button>
                <Button type="primary" onClick={handleAddRectangleClick}>
                    Add Rectangle
                </Button>
                <Button type="primary" onClick={handleAddSquareClick}>
                    Add Square
                </Button>
                <Button type="primary" onClick={handleAddCircleClick}>
                    Add Circle
                </Button>
            </Flex>
            <Flex style={{ width: "100%", height: 600, maxWidth: 1440 }} vertical align={"center"} justify={"flex-start"} gap={50}>
                <MapContainer center={[51.1065, 71.4255]} zoom={17} style={{ width: "100%", height: "600px", cursor: (addingPoint || addingPolygon || addingLine || addingRectangle || addingSquare || addingCircle) ? 'crosshair' : 'auto' }}>
                    <LayersControl position="topright">
                        <BaseLayer checked name="Standard">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </BaseLayer>
                        <BaseLayer name="OpenStreetMap">
                            <TileLayer
                                url="https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"
                            />
                        </BaseLayer>
                        <BaseLayer name="Satellite">
                            <TileLayer
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </BaseLayer>
                    </LayersControl>
                    <PointAdder onAddPoint={handleAddPoint} adding={addingPoint} polygons={polygons} lines={lines} rectangles={rectangles} squares={squares} />
                    <PolygonAdder onAddPolygon={handleAddPolygon} adding={addingPolygon} polygons={polygons} lines={lines} rectangles={rectangles} squares={squares} />
                    <LineAdder onAddLine={handleAddLine} adding={addingLine} polygons={polygons} lines={lines} rectangles={rectangles} squares={squares} />
                    <RectangleAdder onAddRectangle={handleAddRectangle} adding={addingRectangle} polygons={polygons} lines={lines} rectangles={rectangles} squares={squares} />
                    <SquareAdder onAddSquare={handleAddSquare} adding={addingSquare} polygons={polygons} lines={lines} rectangles={rectangles} squares={squares} />
                    <CircleAdder onAddCircle={handleAddCircle} adding={addingCircle} polygons={polygons} lines={lines} rectangles={rectangles} squares={squares} />
                    {circles.map((circle, idx) => {
                        if (idx === 0) {
                            return (
                                <Circle key={idx} center={circle.center} radius={circle.radius} pathOptions={{ color: clrs.whiterGreen, fillOpacity: 0.4 }} interactive={false} className={"my-circle"} />
                            )
                        }
                        if (idx === 1) {
                            return (
                                <Circle key={idx} center={circle.center} radius={circle.radius} pathOptions={{ color: clrs.yellow, fillOpacity: 0.4 }} interactive={false} className={"my-circle"} />
                            )
                        }
                        return (
                            <Circle key={idx} center={circle.center} radius={circle.radius} pathOptions={{ color: clrs.red, fillOpacity: 0.4 }} interactive={false} className={"my-circle"} />
                        )
                    })}
                    {points.map((point, idx) => (
                        <Marker key={idx} position={point} icon={L.divIcon({
                            className: 'custom-marker',
                            html: '<div class="circle"></div>',
                            iconSize: [10, 10]
                        })} />
                    ))}
                    {polygons.map((polygon, idx) => (
                        <Polygon key={idx} positions={polygon} pathOptions={{ color: clrs.whiterBlacker }} />
                    ))}
                    {lines.map((line, idx) => (
                        <Polyline key={idx} positions={line} pathOptions={{ color: 'blue' }} />
                    ))}
                    {rectangles.map((rectangle, idx) => (
                        <Rectangle key={idx} bounds={rectangle} pathOptions={{ color: 'blue' }} />
                    ))}
                    {squares.map((square, idx) => (
                        <>
                            {drawGrid(square)}
                            {/*<Polygon key={idx} positions={square} pathOptions={{ color: 'black', weight: 0.2 }} />*/}

                        </>
                    ))}

                </MapContainer>
            </Flex>
            <Footer />
            <style>{`
        .custom-marker .circle {
          width: 10px;
          height: 10px;
          background-color: red;
          border-radius: 50%;
        }
        .custom-marker .circle.corner {
          background-color: green;
        }
        .custom-marker .circle.temp-point {
          background-color: yellow;
        }
      `}</style>
        </Flex>
    );
};

export default MonitoringPage;

