import {createSlice} from "@reduxjs/toolkit";

let initialState = {
    isClosed: localStorage.getItem('isClosedNav') !== null ? (localStorage.getItem('isClosedNav') === 'true' ? true : false) : true
}

const platformNavSlice = createSlice({
    name: 'platformNavSlice',
    initialState,
    reducers: {
        toggleClosed(state) {
            if (state.isClosed) {
                state.isClosed = false;
                localStorage.setItem('isClosedNav', false);
            } else {
                state.isClosed = true;
                localStorage.setItem('isClosedNav', true);
            }

        },
        setClosed(state, action) {
            state.isClosed = action.payload;
            localStorage.setItem('isClosedNav', action.payload);
        },
    },
});

export const {toggleClosed, setClosed} = platformNavSlice.actions;

export default platformNavSlice.reducer;