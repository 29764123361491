import React from 'react';
import {Flex, Form, Input} from "antd";
import Header from "../components/Header/Header";
import MyText from "../../components/UI/Text/MyText";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import classes from "../global_styles.module.css";
import FormItem from "antd/es/form/FormItem";
import {KeyOutlined, UserOutlined} from "@ant-design/icons";
import BlueButton from "../../components/UI/Buttons/BlueButton";
import {Link, useNavigate} from "react-router-dom";
import Footer from "../components/Footer/Footer";
import {useSelector} from "react-redux";

const ProfilePage = () => {
    const {user, isLoading} = useSelector(state => state.user);

    const navigate = useNavigate();

    function getRole(roleName) {
        if (roleName === 'admin') {
            return <IntlMessage id={'role.admin'}/>
        }
        if (roleName === 'user') {
            return <IntlMessage id={'role.user'}/>
        }
    }

    if (!user) {
        navigate('/login')
    }

    return (
        <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"} justify={"flex-start"} gap={50}>
            <Header/>
            <Flex style={{width: "100%", maxWidth: 1440, minHeight: "60vh"}} vertical align={"center"} justify={"flex-start"} gap={50}>
                <Flex style={{
                    width: "100%",
                    position: "relative",
                    padding: "50px 50px",
                    borderRadius: 15,
                    background: "linear-gradient(180deg, rgba(0,89,255,1) 0%, rgba(76,162,247,1) 100%)"
                }}>
                    <MyText strong size={"large"} uppercase style={{color: "white"}}>
                        <IntlMessage id={'profile'}/>
                    </MyText>
                </Flex>
                <Flex align={"flex-start"} justify={"flex-start"}
                      vertical
                      style={{
                          width: "100%"
                      }}
                      gap={15}>
                    <Flex align={"center"} justify={"center"} gap={10}>
                        <MyText strong>
                            <IntlMessage id={'firstName'}/>:
                        </MyText>
                        {user?.profile?.name}
                    </Flex>
                    <Flex align={"center"} justify={"center"} gap={10}>
                        <MyText strong>
                            <IntlMessage id={'username'}/>:
                        </MyText>
                        {user?.username}
                    </Flex>
                    <Flex align={"center"} justify={"center"} gap={10}>
                        <MyText strong>
                            <IntlMessage id={'role'}/>:
                        </MyText>
                        {
                            getRole(user?.role.roleName)
                        }
                    </Flex>
                </Flex>
                <Flex align={"flex-start"} justify={"flex-start"}
                      vertical
                      style={{
                          width: "100%"
                      }}
                      gap={15} wrap={"wrap"}>
                    <Link to={'/my-projects'}>
                        <BlueButton>
                            <IntlMessage id={'myProjects'}/>
                        </BlueButton>
                    </Link>
                </Flex>
            </Flex>
            <Footer/>
        </Flex>
    );
};

export default ProfilePage;