import React from 'react';
import { FloatButton } from 'antd';
import {clrs} from "../../../constants/colors";




const BackTop = () => (

    <FloatButton.BackTop
        duration={50}

        style={{
            backgroundColor: clrs.blueForIcon,

            border: "0px",
            right:"110px",

    }}/>
);
export default BackTop;