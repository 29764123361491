export class UsernameValidator {
    static USERNAME_PATTERN = /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/;

    static isValid(username) {
        if (username === null || username.trim() === '') {
            return false;
        }
        if (username.includes(" ")) {
            return false;
        }
        if (username.length < 5 || username.length > 20) {
            return false;
        }
        return UsernameValidator.USERNAME_PATTERN.test(username);
    }
}
