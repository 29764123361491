export class PasswordValidator {

    static isValid(password) {
        const lengthRule = password.length >= 8 && password.length <= 30;
        const digitRule = /[0-9]/.test(password);
        const qwertySequenceRule = !(/qwert|wert|erty|rtyu|tyui|yuio|uiop|asdf|sdfg|dfgh|fghj|ghjk|hjkl|zxcv|xcvb|cvbn|vbnm|poiuy|lkjh|nm,./.test(password.toLowerCase()));
        const whitespaceRule = !(/\s/.test(password));

        return lengthRule && digitRule && qwertySequenceRule && whitespaceRule;
    }
}