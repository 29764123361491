const world = [
    [
        [120.8686333, 38.65903686],
        [120.8687333, 38.65923686],
        [120.8688333, 38.65943686],
        [120.8689333, 38.65963686],
        [120.8690333, 38.65983686],
        [120.8691333, 38.66003686],
        [120.8692333, 38.66023686],
        [120.8693333, 38.66043686],
        [120.8694333, 38.66063686],
        [120.8695333, 38.66083686],
        [120.8696333, 38.66103686],
        [120.8697333, 38.66123686],
        [120.8698333, 38.66143686],
        [120.8699333, 38.66163686],
        [120.8700333, 38.66183686],
        [120.8701333, 38.66203686],
        [120.8702333, 38.66223686],
        [120.8703333, 38.66243686],
        [120.8704333, 38.66263686],
        [120.8705333, 38.66283686],
        [120.8706333, 38.66303686],
        [120.8707333, 38.66323686],
        [120.8708333, 38.66343686],
        [120.8709333, 38.66363686],
        [120.8710333, 38.66383686],
        [120.8711333, 38.66403686],
        [120.8712333, 38.66423686],
        [120.8713333, 38.66443686],
        [120.8714333, 38.66463686],
        [120.8715333, 38.66483686],
        [120.8716333, 38.66503686],
        [120.8717333, 38.66523686],
        [120.8718333, 38.66543686],
        [120.8719333, 38.66563686],
        [120.8720333, 38.66583686],
        [120.8721333, 38.66603686],
        [120.8722333, 38.66623686],
        [120.8723333, 38.66643686],
        [120.8724333, 38.66663686],
        [120.8725333, 38.66683686],
        [120.8726333, 38.66703686],
        [120.8727333, 38.66723686],
        [120.8728333, 38.66743686],
        [120.8729333, 38.66763686],
        [120.8730333, 38.66783686],
        [120.8731333, 38.66803686],
        [120.8732333, 38.66823686],
        [120.8733333, 38.66843686],
        [120.8734333, 38.66863686],
        [120.8735333, 38.66883686],
        [120.8736333, 38.66903686],
        [120.8737333, 38.66923686],
        [120.8738333, 38.66943686],
        [120.8739333, 38.66963686],
        [120.8740333, 38.66983686],
        [120.8741333, 38.67003686],
        [120.8742333, 38.67023686],
        [120.8743333, 38.67043686],
        [120.8744333, 38.67063686],
        [120.8745333, 38.67083686],
        [120.8746333, 38.67103686],
        [120.8747333, 38.67123686],
        [120.8748333, 38.67143686],
        [120.8749333, 38.67163686],
        [120.8750333, 38.67183686],
        [120.8751333, 38.67203686],
        [120.8752333, 38.67223686],
        [120.8753333, 38.67243686],
        [120.8754333, 38.67263686],
        [120.8755333, 38.67283686],
        [120.8756333, 38.67303686],
        [120.8757333, 38.67323686],
        [120.8758333, 38.67343686],
        [120.8759333, 38.67363686],
        [120.8760333, 38.67383686],
        [120.8761333, 38.67403686],
        [120.8762333, 38.67423686],
        [120.8763333, 38.67443686],
        [120.8764333, 38.67463686],
        [120.8765333, 38.67483686],
        [120.8766333, 38.67503686],
        [120.8767333, 38.67523686],
        [120.8768333, 38.67543686],
        [120.8769333, 38.67563686],
        [120.8770333, 38.67583686],
        [120.8771333, 38.67603686],
        [120.8772333, 38.67623686],
        [120.8773333, 38.67643686],
        [120.8774333, 38.67663686],
        [120.8775333, 38.67683686],
        [120.8776333, 38.67703686],
        [120.8777333, 38.67723686],
        [120.8778333, 38.67743686],
        [120.8779333, 38.67763686],
        [120.8780333, 38.67783686],
        [120.8781333, 38.67803686],
        [120.8782333, 38.67823686],
        [120.8783333, 38.67843686],
        [120.8784333, 38.67863686],
        [120.8785333, 38.67883686],
        [120.8786333, 38.67903686],
        [120.8787333, 38.67923686],
        [120.8788333, 38.67943686],
        [120.8789333, 38.67963686],
        [120.8790333, 38.67983686],
        [120.8791333, 38.68003686],
        [120.8792333, 38.68023686],
        [120.8793333, 38.68043686],
        [120.8794333, 38.68063686],
        [120.8795333, 38.68083686],
        [120.8796333, 38.68103686],
        [120.8797333, 38.68123686],
        [120.8798333, 38.68143686],
        [120.8799333, 38.68163686],
        [120.8800333, 38.68183686],
        [120.8801333, 38.68203686],
        [120.8802333, 38.68223686],
        [120.8803333, 38.68243686],
        [120.8804333, 38.68263686],
        [120.8805333, 38.68283686],
        [120.8806333, 38.68303686],
        [120.8807333, 38.68323686],
        [120.8808333, 38.68343686],
        [120.8809333, 38.68363686],
        [120.8810333, 38.68383686],
        [120.8811333, 38.68403686],
        [120.8812333, 38.68423686],
        [120.8813333, 38.68443686],
        [120.8814333, 38.68463686],
        [120.8815333, 38.68483686],
        [120.8816333, 38.68503686],
        [120.8817333, 38.68523686],
        [120.8818333, 38.68543686],
        [120.8819333, 38.68563686],
        [120.8820333, 38.68583686],
        [120.8821333, 38.68603686],
        [120.8822333, 38.68623686],
        [120.8823333, 38.68643686],
        [120.8824333, 38.68663686],
        [120.8825333, 38.68683686],
        [120.8826333, 38.68703686],
        [120.8827333, 38.68723686],
        [120.8828333, 38.68743686],
        [120.8829333, 38.68763686],
        [120.8830333, 38.68783686],
        [120.8831333, 38.68803686],
        [120.8832333, 38.68823686],
        [120.8833333, 38.68843686],
        [120.8834333, 38.68863686],
        [120.8835333, 38.68883686],
        [120.8836333, 38.68903686],
        [120.8837333, 38.68923686],
        [120.8838333, 38.68943686],
        [120.8839333, 38.68963686],
        [120.8840333, 38.68983686],
        [120.8841333, 38.69003686],
        [120.8842333, 38.69023686],
        [120.8843333, 38.69043686],
        [120.8844333, 38.69063686],
        [120.8845333, 38.69083686],
        [120.8846333, 38.69103686],
        [120.8847333, 38.69123686],
        [120.8848333, 38.69143686],
        [120.8849333, 38.69163686],
        [120.8850333, 38.69183686],
        [120.8851333, 38.69203686],
        [120.8852333, 38.69223686],
        [120.8853333, 38.69243686],
        [120.8854333, 38.69263686],
        [120.8855333, 38.69283686],
        [120.8856333, 38.69303686],
        [120.8857333, 38.69323686],
        [120.8858333, 38.69343686],
        [120.8859333, 38.69363686],
        [120.8860333, 38.69383686],
        [120.8861333, 38.69403686],
        [120.8862333, 38.69423686],
        [120.8863333, 38.69443686],
        [120.8864333, 38.69463686],
        [120.8865333, 38.69483686],
        [120.8866333, 38.69503686],
        [120.8867333, 38.69523686],
        [120.8868333, 38.69543686],
        [120.8869333, 38.69563686],
        [120.8870333, 38.69583686],
        [120.8871333, 38.69603686],
        [120.8872333, 38.69623686],
        [120.8873333, 38.69643686],
        [120.8874333, 38.69663686],
        [120.8875333, 38.69683686],
        [120.8876333, 38.69703686],
        [120.8877333, 38.69723686],
        [120.8878333, 38.69743686],
        [120.8879333, 38.69763686],
        [120.8880333, 38.69783686],
        [120.8881333, 38.69803686],
        [120.8882333, 38.69823686],
        [120.8883333, 38.69843686],
        [120.8884333, 38.69863686],
        [120.8885333, 38.69883686],
        [120.8886333, 38.69903686],
        [120.8887333, 38.69923686],
        [120.8888333, 38.69943686],
        [120.8889333, 38.69963686],
        [120.8890333, 38.69983686],
        [120.8891333, 38.70003686],
        [120.8892333, 38.70023686],
        [120.8893333, 38.70043686],
        [120.8894333, 38.70063686],
        [120.8895333, 38.70083686],
        [120.8896333, 38.70103686],
        [120.8897333, 38.70123686],
        [120.8898333, 38.70143686],
        [120.8899333, 38.70163686],
        [120.8900333, 38.70183686],
        [120.8901333, 38.70203686],
        [120.8902333, 38.70223686],
        [120.8903333, 38.70243686],
        [120.8904333, 38.70263686],
        [120.8905333, 38.70283686],
        [120.8906333, 38.70303686],
        [120.8907333, 38.70323686],
        [120.8908333, 38.70343686],
        [120.8909333, 38.70363686],
        [120.8910333, 38.70383686],
    ]
];

export default world;
