import React, {useCallback, useEffect, useMemo, useRef, useState, useTransition} from 'react';
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Button, DatePicker, Drawer, Flex, Form, Input, message, Modal, notification, Segmented, Tooltip} from "antd";
import GisService from "../../services/GisService";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import AdminGisService from "../../services/AdminGisService";
import Header from "../components/Header/Header";
import MyText from "../../components/UI/Text/MyText";
import BlueButton from "../../components/UI/Buttons/BlueButton";
import {CloseOutlined, EditOutlined, EyeOutlined, PlusOutlined, UndoOutlined, UserOutlined} from "@ant-design/icons";
import Footer from "../components/Footer/Footer";
import classes from "../global_styles.module.css";
import FormItem from "antd/es/form/FormItem";
import GreyButton from "../../components/UI/Buttons/GreyButton";
import {
    Circle,
    LayersControl,
    MapContainer,
    Marker,
    Polygon,
    Polyline, Popup,
    Rectangle,
    TileLayer,
    useMapEvents,
    useMap
} from "react-leaflet";
import {clrs} from "../../constants/colors";
import L, {latLng} from "leaflet";
import interpolation from './interpolation.json';
import AdminUserService from "../../services/AdminUserService";
import {useTranslation} from "react-i18next";
import antdRuRU from 'antd/es/locale/ru_RU';
import Card from "../../components/LoadingComponents/Card";
import dayjs from "dayjs";
import { Range } from "react-range";

const { RangePicker } = DatePicker;
const { BaseLayer } = LayersControl;


const STICKING_DISTANCE = 5;  // Reduced sticking distance

const getSquareCorners = (center, size) => {
    const halfSize = size / 2;
    const latAdjustment = halfSize / 111320; // Convert meters to degrees latitude
    const lngAdjustment = halfSize / (40075000 * Math.cos(parseFloat(center.lat) * Math.PI / 180) / 360); // Convert meters to degrees longitude based on latitude

    return [
        new L.latLng(parseFloat(center.lat) + latAdjustment, parseFloat(center.lng) - lngAdjustment),
        new L.latLng(parseFloat(center.lat) + latAdjustment, parseFloat(center.lng) + lngAdjustment),
        new L.latLng(parseFloat(center.lat) - latAdjustment, parseFloat(center.lng) + lngAdjustment),
        new L.latLng(parseFloat(center.lat) - latAdjustment, parseFloat(center.lng) - lngAdjustment)
    ];
};

const getClosestPoint = (polygons, latlng) => {
    let closestPoint = { point: null, distance: Infinity };

    const checkClosestPoint = (point) => {
        const distance = latLng(point).distanceTo(latlng);
        if (distance < closestPoint.distance) {
            closestPoint = { point, distance };
        }
    };

    const checkClosestEdge = (points) => {
        points.forEach((point, index) => {
            const nextIndex = (index + 1) % points.length;
            const nextPoint = points[nextIndex];
            const edgePoint = getClosestPointOnEdge(point, nextPoint, latlng);
            const edgeDistance = latLng(edgePoint).distanceTo(latlng);
            if (edgeDistance < closestPoint.distance) {
                closestPoint = { point: edgePoint, distance: edgeDistance };
            }
        });
    };

    polygons.forEach(polygon => {
        polygon.forEach(checkClosestPoint);
        checkClosestEdge(polygon);
    });

    return closestPoint;
};

const getClosestPointOnEdge = (start, end, latlng) => {
    const startLatLng = latLng(start);
    const endLatLng = latLng(end);

    const dX = endLatLng.lng - startLatLng.lng;
    const dY = endLatLng.lat - startLatLng.lat;

    if (dX === 0 && dY === 0) {
        return startLatLng;
    }

    const t = ((latlng.lng - startLatLng.lng) * dX + (latlng.lat - startLatLng.lat) * dY) / (dX * dX + dY * dY);

    if (t < 0) {
        return startLatLng;
    } else if (t > 1) {
        return endLatLng;
    }

    return latLng(startLatLng.lat + t * dY, startLatLng.lng + t * dX);
};

const BuildingAdder = ({ onAddPolygon, adding, polygons }) => {
    const [polygon, setPolygon] = useState([]);
    const [tempPoint, setTempPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, e.latlng);
                if (polygon.length > 0 && latLng(polygon[0]).distanceTo(e.latlng) < STICKING_DISTANCE) {
                    onAddPolygon([...polygon, polygon[0]]);
                    setPolygon([]);
                    setTempPoint(null);
                } else {
                    setPolygon([...polygon, closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng]);
                }
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons,  e.latlng);
                if (polygon.length > 0 && latLng(polygon[0]).distanceTo(e.latlng) < STICKING_DISTANCE) {
                    setTempPoint(polygon[0]);
                } else {
                    setTempPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
                }
            }
        }
    });

    useEffect(() => {
        setPolygon([]);
        setTempPoint(null);
    }, [adding]);

    return (
        <>
            {polygon.length > 0 && (
                <Polyline positions={[...polygon, ...(tempPoint ? [tempPoint] : [])]} pathOptions={{ color: 'yellow', dashArray: '5, 5' }} />
            )}
            {tempPoint && adding && (
                <Marker position={tempPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
        </>
    );
};

const SensorPointAdder = ({ onAddPoint, adding, polygons}) => {
    const [tempPoint, setTempPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, e.latlng);
                onAddPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, e.latlng);
                setTempPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
            }
        }
    });

    useEffect(() => {
        setTempPoint(null);
    }, [adding]);

    return (
        <>
            {tempPoint && adding && (
                <Marker position={tempPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
        </>
    );
};

const MonitoringPointAdder = ({ onAddPoint, adding, polygons}) => {
    const [tempPoint, setTempPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, e.latlng);
                onAddPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, e.latlng);
                setTempPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
            }
        }
    });

    useEffect(() => {
        setTempPoint(null);
    }, [adding]);

    return (
        <>
            {tempPoint && adding && (
                <Marker position={tempPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
        </>
    );
};

const ConstructAreaAdder = ({ onAddPolygon, adding, polygons }) => {
    const [polygon, setPolygon] = useState([]);
    const [tempPoint, setTempPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, e.latlng);
                if (polygon.length > 0 && latLng(polygon[0]).distanceTo(e.latlng) < STICKING_DISTANCE) {
                    onAddPolygon([...polygon, polygon[0]]);
                    setPolygon([]);
                    setTempPoint(null);
                } else {
                    setPolygon([...polygon, closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng]);
                }
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons,  e.latlng);
                if (polygon.length > 0 && latLng(polygon[0]).distanceTo(e.latlng) < STICKING_DISTANCE) {
                    setTempPoint(polygon[0]);
                } else {
                    setTempPoint(closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng);
                }
            }
        }
    });

    useEffect(() => {
        setPolygon([]);
        setTempPoint(null);
    }, [adding]);

    return (
        <>
            {polygon.length > 0 && (
                <Polyline positions={[...polygon, ...(tempPoint ? [tempPoint] : [])]} pathOptions={{ color: 'yellow', dashArray: '5, 5' }} />
            )}
            {tempPoint && adding && (
                <Marker position={tempPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
        </>
    );
};

const MonitoringZoneAdder = ({ onAddSquare, adding, polygons }) => {
    const [tempSquare, setTempSquare] = useState(null);
    const [startingPoint, setStartingPoint] = useState(null);

    useMapEvents({
        click(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, e.latlng);
                const center = closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng;
                const corners = getSquareCorners(center, 500); // Size is now 50 units
                onAddSquare(corners);
                setTempSquare(null);
            }
        },
        mousemove(e) {
            if (adding) {
                const closestPoint = getClosestPoint(polygons, e.latlng);
                const center = closestPoint.distance < STICKING_DISTANCE ? closestPoint.point : e.latlng;
                const corners = getSquareCorners(center, 500); // Size is now 50 units
                setTempSquare(corners);
                setStartingPoint(center);
            }
        }
    });

    return (
        <>
            {startingPoint && adding && (
                <Marker position={startingPoint} icon={L.divIcon({
                    className: 'custom-marker',
                    html: '<div class="circle temp-point"></div>',
                    iconSize: [10, 10]
                })} />
            )}
            {tempSquare && adding && (
                <>
                    <Polygon positions={tempSquare} pathOptions={{ color: 'yellow', dashArray: '5, 5' }} />
                </>
            )}
        </>
    );
};

// Function to calculate grid matrix and weights
const calculateGridMatrix = (corners, points) => {
    const [topLeft, topRight, bottomRight, bottomLeft] = corners;
    const gridMatrix = [];

    const numDivisions = 100; // 100x100 grid
    const latStep = (topLeft.lat - bottomLeft.lat) / numDivisions;
    const lngStep = (topRight.lng - topLeft.lng) / numDivisions;

    for (let i = 0; i < numDivisions; i++) {
        for (let j = 0; j < numDivisions; j++) {
            const squareTopLeft = {
                lat: topLeft.lat - i * latStep,
                lng: topLeft.lng + j * lngStep
            };
            const squareTopRight = {
                lat: squareTopLeft.lat,
                lng: squareTopLeft.lng + lngStep
            };
            const squareBottomRight = {
                lat: squareTopLeft.lat - latStep,
                lng: squareTopRight.lng
            };
            const squareBottomLeft = {
                lat: squareBottomRight.lat,
                lng: squareTopLeft.lng
            };

            const squareCenter = {
                lat: (squareTopLeft.lat + squareBottomRight.lat) / 2,
                lng: (squareTopLeft.lng + squareBottomRight.lng) / 2
            };

            // Check if the square contains any of the points
            const pointInSquare = points.find(point =>
                squareTopLeft.lat >= point.lat && point.lat >= squareBottomRight.lat &&
                squareTopLeft.lng <= point.lng && point.lng <= squareTopRight.lng
            );

            let weight;
            if (pointInSquare) {
                weight = pointInSquare.weight;
            } else {
                let a = 1e-6;
                let b = 1e-6;
                let p = 3.5;  // Example power parameter

                for (const point of points) {
                    const distance = new L.latLng(point.lat, point.lng).distanceTo(new L.latLng(squareCenter.lat, squareCenter.lng));
                    a += point.weight / Math.pow(distance, p);
                    b += 1 / Math.pow(distance, p);
                }

                weight = a / b;

                // let a = 1e-6;
                // let b = 1e-6;
                // for (const point of points) {
                //     const distance = new L.latLng(point.lat, point.lng).distanceTo(new L.latLng(squareCenter.lat, squareCenter.lng));
                //     a += point.weight / distance;
                //     b += 1 / distance;
                // }
                // weight = a / b;
            }

            gridMatrix.push({
                square: [squareTopLeft, squareTopRight, squareBottomRight, squareBottomLeft],
                weight
            });
        }
    }

    return gridMatrix;
};

// Function to get color based on weight
const getColorForWeight = (weight, minWeight, maxWeight) => {
    const minColor = [255, 255, 255]; // white
    const maxColor = [254, 0, 0]; // red

    const factor = (weight - minWeight) / (maxWeight - minWeight);

    const r = Math.floor(minColor[0] + factor * (maxColor[0] - minColor[0]));
    const g = Math.floor(minColor[1] + factor * (maxColor[1] - minColor[1]));
    const b = Math.floor(minColor[2] + factor * (maxColor[2] - minColor[2]));

    return `rgb(${255}, ${255 - weight}, ${255 - weight})`;
};

const findMedianWeight = (weights) => {
    const sortedWeights = [...weights].sort((a, b) => a - b);
    const middleIndex = Math.floor(sortedWeights.length / 2);

    if (sortedWeights.length % 2 === 0) {
        return (sortedWeights[middleIndex - 1] + sortedWeights[middleIndex]) / 2;
    } else {
        return sortedWeights[middleIndex];
    }
};

const MapClickHandler = ({ gridMatrix, setPopupInfo, setWeight, currentMonitoringPoint }) => {
    useMapEvents({
        click(e) {
            const { lat, lng } = e.latlng;
            const clickedCell = gridMatrix.find(cell =>
                cell.square[0].lat >= lat && lat >= cell.square[2].lat &&
                cell.square[0].lng <= lng && lng <= cell.square[1].lng
            );
            if (clickedCell) {
                if (currentMonitoringPoint) {
                    const distance = new L.latLng(currentMonitoringPoint.point.latitude, currentMonitoringPoint.point.longitude).distanceTo(new L.latLng(lat, lng));
                    if (distance > 5) {
                        setPopupInfo({ lat, lng, weight: clickedCell.weight });
                    } else {
                        setWeight(clickedCell.weight);
                    }

                } else {
                    setPopupInfo({ lat, lng, weight: clickedCell.weight });
                }
            }
        }
    });
    return null;
};

// Draw the grid on the map
const Grid = ({corners, points, isEditing, setWeight, setCurrentMonitoringPoint, currentMonitoringPoint}) => {
    const [popupInfo, setPopupInfo] = useState(null);

    const gridMatrix = calculateGridMatrix(corners, points);

    // Find max, min, and median weights
    const weights = gridMatrix.map(cell => cell.weight);
    const maxWeight = Math.max(...weights);
    const minWeight = Math.min(...weights);

    const polygons = gridMatrix.map((gridCell, idx) => (
        <Polygon
            interactive={false}
            key={idx}
            positions={gridCell.square}
            pathOptions={{
                color: 'none',
                fillColor: getColorForWeight(gridCell.weight, minWeight, maxWeight),
                fillOpacity: 0.5,
                weight: 1
            }}
        />
    ));

    useEffect(() => {
        if (popupInfo) {
            setCurrentMonitoringPoint(null);
        }
    }, [popupInfo]);

    return (
        <>
            {polygons}
            {!isEditing &&
                <MapClickHandler gridMatrix={gridMatrix} setPopupInfo={setPopupInfo} setWeight={setWeight} currentMonitoringPoint={currentMonitoringPoint} />
            }
            {popupInfo && !isEditing && (
                <Popup position={[popupInfo.lat, popupInfo.lng]}>
                    <div><IntlMessage id={'gis.noiseLevel'}/>: {((popupInfo.weight * 0.125045282) + 47.74861115).toFixed(2)} дБ</div>
                </Popup>
            )}
        </>
    );
};

const GisPage = () => {
    const {user, isLoading} = useSelector(state => state.user);
    const navigate = useNavigate();
    const [gis, setGis] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [addingBuilding, setAddingBuilding] = useState(false);
    const [addingSensorPoint, setAddingSensorPoint] = useState(false);
    const [addingTempSensorPoint, setAddingTempSensorPoint] = useState(false);
    const [addingMonitoringZone, setAddingMonitoringZone] = useState(false);
    const [addingConstructArea, setAddingConstructArea] = useState(false);
    const [addingMonitoringPoint, setAddingMonitoringPoint] = useState(false);

    const [currentInterpolation, setCurrentInterpolation] = useState(interpolation);
    const [interpolations, setInterpolations] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(dayjs().add(1, 'day').toISOString().split('T')[0]);
    const [rangeValues, setRangeValues] = useState([1]);

    const [fetchLoading, setFetchLoading] = useState(false);

    const [tempSensorPoint, setTempSensorPoint] = useState(null);

    const {id} = useParams();

    const [openUsers, setOpenUsers] = useState(false);
    const [searchUsers, setSearchUsers] = useState([]);

    const {t} = useTranslation();

    const [currentMonitoringPoint, setCurrentMonitoringPoint] = useState(null);
    const [currentMonitoringPointWeight, setCurrentMonitoringPointWeight] = useState(0);

    const [mapType, setMapType] = useState('Шум');

    useEffect(() => {
        if (id) {
            init()
        }
    }, [id]);

    useEffect(() => {
        if (interpolations.length > 0) {
            setCurrentInterpolation(interpolations.at(interpolations.length - rangeValues[0]))
        }
    }, [rangeValues]);

    const init = async () => {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await GisService.getById(id)
            .then((r) => {
                setGis(r.data);
            })
            .catch(() => {

            })

        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 5);
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);

        await handleGetInterpolationData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0])
    }

    const handleGetInterpolationData = async (startDate, endDate) => {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        setFetchLoading(true);

        if (mapType === 'Шум') {
            await GisService.getInterpolationData(
                id,
                startDate,
                endDate
            )
                .then((r) => {
                    message.destroy();
                    message.success(<IntlMessage id={'success'}/>, 5);
                    if (r.data.length > 0) {
                        setCurrentInterpolation(r.data[0])
                    } else {
                        setCurrentInterpolation(null);
                    }
                    setInterpolations(r.data);
                    setRangeValues([r.data.length])
                    setLoading(false);
                    setTimeout(() => {
                        setFetchLoading(false);
                    }, 200)

                })
                .catch((r) => {
                    console.log(r)
                    message.destroy();
                    message.error(<IntlMessage id={'error'}/>, 5);
                    setLoading(false);
                    setTimeout(() => {
                        setFetchLoading(false);
                    }, 200)
                })
        } else {
            await GisService.getInterpolationDataVibration(
                id,
                startDate,
                endDate
            )
                .then((r) => {
                    message.destroy();
                    message.success(<IntlMessage id={'success'}/>, 5);
                    if (r.data.length > 0) {
                        setCurrentInterpolation(r.data[0])
                    } else {
                        setCurrentInterpolation(null);
                    }
                    setInterpolations(r.data);
                    setRangeValues([r.data.length])
                    setLoading(false);
                    setTimeout(() => {
                        setFetchLoading(false);
                    }, 200)

                })
                .catch((r) => {
                    console.log(r)
                    message.destroy();
                    message.error(<IntlMessage id={'error'}/>, 5);
                    setLoading(false);
                    setTimeout(() => {
                        setFetchLoading(false);
                    }, 200)
                })
        }


    }

    function getTooltipRange() {
        return new Date(interpolations.at(interpolations.length - rangeValues[0])?.createdAt).toLocaleString()
    }
    function getTooltipRangeAtIndex(index) {
        return new Date(interpolations.at(interpolations.length - index - 1)?.createdAt).toLocaleString()
    }

    const handleAddBuilding = (polygon) => {
        setAddingBuilding(false);
        const lats = [];
        const lngs = [];
        polygon.forEach(p => {
            lats.push(p.lat);
            lngs.push(p.lng);
        });
        message.loading(<IntlMessage id={'loading'}/>, 0);
        AdminGisService.addBuilding(id, lats, lngs)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setGis({
                    ...gis,
                    buildings: [...gis.buildings, r.data]
                })
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    const handleAddConstructArea = (polygon) => {
        setAddingConstructArea(false);
        const lats = [];
        const lngs = [];
        polygon.forEach(p => {
            lats.push(p.lat);
            lngs.push(p.lng);
        });
        message.loading(<IntlMessage id={'loading'}/>, 0);
        AdminGisService.addConstructArea(id, lats, lngs)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setGis({
                    ...gis,
                    constructArea: r.data
                })
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    const handleAddSensorPoint = (point) => {
        setAddingSensorPoint(false);
        setTempSensorPoint(point);
    }

    const handleAddMonitoringPoint = (point) => {
        console.log(point);
        message.loading(<IntlMessage id={'loading'}/>, 0);
        let lat = point.lat;
        let lng = point.lng;
        AdminGisService.addMonitoringPoint(id, lat, lng)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                console.log(r.data);
                setGis({
                    ...gis,
                    monitoringPoints: [...gis.monitoringPoints, r.data]
                });
                setAddingMonitoringPoint(false);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                setAddingMonitoringPoint(false);
            })
    }

    function handleSaveSensorPoint(lat, lng, alpha, name) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        AdminGisService.addSensorPoint(id, lat, lng, alpha, name)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                console.log(r.data);
                setGis({
                    ...gis,
                    sensors: [...gis.sensors, r.data]
                });
                setAddingTempSensorPoint(false);
                setAddingSensorPoint(false);
                setTempSensorPoint(null);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                setAddingTempSensorPoint(false);
                setAddingSensorPoint(false);
                setTempSensorPoint(null);
            })
    }

    const handleAddMonitoringZone = (corners) => {
        setAddingMonitoringZone(false);
        const lats = [];
        const lngs = [];
        corners.forEach(p => {
            lats.push(p.lat);
            lngs.push(p.lng);
        });
        message.loading(<IntlMessage id={'loading'}/>, 0);
        AdminGisService.addMonitoringZone(id, lats, lngs)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                setGis({
                    ...gis,
                    monitoringZone: r.data
                })
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            });
    }

    const handleSearchUsers = async (username) => {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await AdminUserService.searchUsers(username)
            .then((r) => {
                message.destroy();
                setSearchUsers(r.data.list);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }
    const handleAddUser = async (username) => {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await AdminGisService.addUserToProject(username, id)
            .then((r) => {
                message.destroy();
                setGis({
                    ...gis,
                    users: r.data.users
                })
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    const handleRemoveUser = async (username) => {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await AdminGisService.removeUserFromProject(username, id)
            .then((r) => {
                message.destroy();
                setGis({
                    ...gis,
                    users: r.data.users
                })
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    useEffect(() => {
        if (!addingSensorPoint && addingTempSensorPoint) {
            message.info(<MyText><IntlMessage id={'gis.addSensorPointMessage'}/></MyText>, 0);
        }
    }, [addingSensorPoint, addingTempSensorPoint]);

    if (!user) {
        navigate('/login')
    }

    if (loading) {
        return (
            <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"}
                  justify={"flex-start"} gap={25}>
                <Header/>
                <Flex style={{width: "100%", maxWidth: 1440, minHeight: "60vh"}} vertical align={"flex-start"}
                      justify={"flex-start"} gap={25}>
                    <Card type={'horizontal-small'}></Card>
                    <Flex style={{width: 300}}>
                        <Card type={'button'}></Card>
                    </Flex>
                    <Flex style={{width: 500}}>
                        <Card type={'button'}></Card>
                    </Flex>
                    <Card type={'horizontal-big'}></Card>

                </Flex>
                <Footer/>
            </Flex>
        );
    }

    return (
        <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"}
              justify={"flex-start"} gap={25}>
            <Drawer title={<IntlMessage id={'gis.users'}/>} onClose={() => {setOpenUsers(false)}} open={openUsers}>
                <Flex style={{
                    width: "100%",
                    position: "relative",
                    padding: 5,
                    minHeight: 100,
                    border: `1px solid ${clrs.whiterBlacker2}`,
                    borderRadius: 8
                }} align={"center"} justify={"flex-start"} gap={10} vertical>
                    <MyText size={"small"} strong><IntlMessage id={'gis.addUser'}/></MyText>
                    <Input placeholder={"Имя пользователя"} style={{width: "100%", height: "2rem"}}
                           onKeyUp={(e) => {if (e.key === "Enter") {
                               handleSearchUsers(e.target.value)
                           }}}
                    />
                    <Flex style={{width: "100%", position: "relative"}} vertical gap={2} align={"center"} justify={"center"}>
                        {searchUsers.map((usr, i) => {
                            return (
                                <Flex key={new Date() + i + "searchUser"} style={{background: "#f5f5f5", width: "100%", padding: 5}} align={"center"} justify={"space-between"}>
                                    <MyText size={"small"}>
                                        {usr.profile?.name} ({usr.username})
                                    </MyText>
                                    {
                                        user.username !== usr.username &&
                                        <Button onClick={() => {handleAddUser(usr.username)}} type={"primary"}>
                                            <PlusOutlined/>
                                        </Button>
                                    }
                                </Flex>
                            )
                        })}

                    </Flex>
                </Flex>
                <br/>
                <Flex style={{
                    width: "100%",
                    position: "relative",
                    padding: 5,
                    minHeight: 100,
                    border: `1px solid ${clrs.whiterBlacker2}`,
                    borderRadius: 8
                }} align={"center"} justify={"flex-start"} gap={10} vertical>
                    <MyText size={"small"} strong><IntlMessage id={'gis.usersList'}/></MyText>
                    {gis?.users.map((usr, i) => {
                        return (
                            <Flex style={{background: "#f5f5f5", width: "100%", padding: 5}} align={"center"} justify={"space-between"}>
                                <MyText size={"small"}>
                                    {usr.profile?.name} ({usr.username})
                                </MyText>
                                {
                                    user.username !== usr.username &&
                                    <Button danger onClick={() => {handleRemoveUser(usr.username)}}>
                                        <CloseOutlined color={"white"}/>
                                    </Button>
                                }
                            </Flex>
                        )
                    })}
                </Flex>
            </Drawer>
            <Header/>
            <Flex style={{width: "100%", maxWidth: 1440, minHeight: "60vh"}} vertical align={"flex-start"}
                  justify={"flex-start"} gap={25}>
                <Flex style={{
                    width: "100%",
                    position: "relative",
                    padding: "25px 50px",
                    borderRadius: 15,
                    background: "linear-gradient(180deg, rgba(0,89,255,1) 0%, rgba(76,162,247,1) 100%)"
                }}>
                    <MyText strong size={"large"} uppercase style={{color: "white"}}>
                        {gis?.name}
                    </MyText>
                </Flex>
                {
                    user?.role?.roleName === 'admin' &&
                    <Flex wrap={"wrap"} align={"flex-start"} justify={"flex-start"}
                          style={{
                              width: "100%"
                          }}
                          gap={15}
                    >
                        {
                            isEdit ?
                                <>
                                    <GreyButton
                                        disabled={addingBuilding || addingSensorPoint || addingTempSensorPoint || addingConstructArea || addingMonitoringZone || addingMonitoringPoint}
                                        onClick={() => {
                                            setIsEdit(false);
                                            setAddingBuilding(false);
                                            setAddingSensorPoint(false);
                                            setAddingTempSensorPoint(false);
                                            setAddingConstructArea(false);
                                            setAddingMonitoringZone(false);
                                            setAddingMonitoringPoint(false);
                                        }}>
                                        <EyeOutlined/>
                                        <span> </span>
                                        <IntlMessage id={'gis.view'}/>
                                    </GreyButton>
                                    <BlueButton
                                        disabled={addingSensorPoint || addingTempSensorPoint || addingConstructArea || addingMonitoringZone || addingMonitoringPoint}
                                        onClick={() => {
                                            setAddingBuilding(!addingBuilding);
                                            setAddingSensorPoint(false);
                                            setAddingTempSensorPoint(false);
                                            setAddingConstructArea(false);
                                            setAddingMonitoringZone(false);
                                            setAddingMonitoringPoint(false);
                                        }}>
                                        <PlusOutlined/>
                                        <span> </span>
                                        <IntlMessage id={'gis.addBuilding'}/>
                                    </BlueButton>
                                    <BlueButton disabled={addingBuilding || addingConstructArea || addingMonitoringZone || addingMonitoringPoint}
                                                onClick={() => {
                                                    setAddingBuilding(false);
                                                    message.destroy();
                                                    if (addingSensorPoint) {
                                                        setAddingSensorPoint(false);
                                                        setAddingTempSensorPoint(false);
                                                        setTempSensorPoint(null);
                                                    } else {
                                                        if (tempSensorPoint) {
                                                            setAddingSensorPoint(false);
                                                            setAddingTempSensorPoint(false);
                                                            setTempSensorPoint(null);
                                                        } else {
                                                            setAddingSensorPoint(true);
                                                            setAddingTempSensorPoint(true);
                                                        }
                                                    }

                                                    setAddingConstructArea(false);
                                                    setAddingMonitoringZone(false);
                                                    setAddingMonitoringPoint(false);
                                                }}>
                                        <PlusOutlined/>
                                        <span> </span>
                                        <IntlMessage id={'gis.addSensorPoint'}/>
                                    </BlueButton>
                                    <BlueButton disabled={addingBuilding || addingMonitoringPoint || addingSensorPoint || addingTempSensorPoint || addingMonitoringZone}
                                                onClick={() => {
                                                    setAddingBuilding(false);
                                                    setAddingSensorPoint(false);
                                                    setAddingTempSensorPoint(false);
                                                    setAddingConstructArea(!addingConstructArea);
                                                    setAddingMonitoringZone(false);
                                                    setAddingMonitoringPoint(false);
                                                }}>
                                        <PlusOutlined/>
                                        <span> </span>
                                        <IntlMessage id={'gis.addConstructArea'}/>
                                    </BlueButton>
                                    <BlueButton disabled={addingBuilding || addingMonitoringPoint || addingSensorPoint || addingTempSensorPoint || addingConstructArea}
                                                onClick={() => {
                                                    setAddingBuilding(false);
                                                    setAddingSensorPoint(false);
                                                    setAddingTempSensorPoint(false);
                                                    setAddingConstructArea(false);
                                                    setAddingMonitoringZone(!addingMonitoringZone);
                                                    setAddingMonitoringPoint(false);
                                                }}>
                                        <PlusOutlined/>
                                        <span> </span>
                                        <IntlMessage id={'gis.addMonitoringZone'}/>
                                    </BlueButton>
                                    <BlueButton disabled={addingBuilding || addingSensorPoint || addingTempSensorPoint || addingConstructArea || addingMonitoringZone}
                                                onClick={() => {
                                                    setAddingBuilding(false);
                                                    setAddingSensorPoint(false);
                                                    setAddingTempSensorPoint(false);
                                                    setAddingConstructArea(false);
                                                    setAddingMonitoringZone(false);
                                                    setAddingMonitoringPoint(!addingMonitoringPoint);
                                                }}>
                                        <PlusOutlined/>
                                        <span> </span>
                                        <IntlMessage id={'gis.addMonitoringPoint'}/>
                                    </BlueButton>
                                </>
                                :
                                <>
                                    <BlueButton onClick={() => {
                                        setIsEdit(true)
                                    }}>
                                        <EditOutlined/>
                                        <span> </span>
                                        <IntlMessage id={'gis.edit'}/>
                                    </BlueButton>
                                    <BlueButton onClick={() => {setOpenUsers(true)}}>
                                        <UserOutlined/>
                                        <span> </span>
                                        <IntlMessage id={'gis.users'}/>
                                    </BlueButton>
                                </>
                        }
                    </Flex>
                }
                <Flex wrap={"wrap"} align={"flex-start"} justify={"flex-start"}
                      style={{
                          width: "100%"
                      }}
                      gap={15}>
                    <RangePicker
                        style={{
                            width: "100%",
                            maxWidth: 400
                        }}
                        size={"large"}
                        placeholder={[t('gis.startDate'), t('gis.endDate')]}
                        defaultValue={[dayjs(),dayjs().add(1, 'day')]}
                        onCalendarChange={(dates, dateStrings, info) => {
                            if (dates[0] && dates[1]) {
                                // handleGetInterpolationData(dateStrings[0], dateStrings[1]);
                                setStartDate(dateStrings[0]);
                                setEndDate(dateStrings[1])
                            } else {
                                setStartDate(null);
                                setEndDate(null)
                            }
                        }}
                    />
                    <Tooltip title={<IntlMessage id={'update'}/>}>
                        <BlueButton onClick={() => {
                            if (startDate && endDate) {
                                handleGetInterpolationData(startDate, endDate);
                            } else {
                                message.warning(<IntlMessage id={'gis.incorrectDates'}/>, 5);
                            }
                        }} size={"small"} strong style={{minWidth: "auto"}}>
                            <UndoOutlined />
                        </BlueButton>
                    </Tooltip>

                </Flex>
                <Flex align={"flex-start"} justify={"flex-start"}
                      style={{
                          width: "100%",
                          position: "relative"
                      }}
                      wrap={"wrap"}
                      gap={15}>
                    {
                        fetchLoading ?
                            <Card type={'horizontal-line'}></Card>
                            :
                            interpolations.length > 0 &&
                            <Range
                                label="Select your value"
                                step={1}
                                min={interpolations.length > 0 ? 1 : 0}
                                max={interpolations.length === 1 ? 2 : interpolations.length}
                                values={rangeValues}
                                onChange={(values) => setRangeValues(values)}
                                renderMark={({ props, index }) => (
                                    <Tooltip title={getTooltipRangeAtIndex(index)}>
                                        <div
                                            {...props}
                                            key={props.key}
                                            style={{
                                                ...props.style,
                                                height: "16px",
                                                width: "5px",
                                                backgroundColor: "#ccc",
                                            }}
                                        />
                                    </Tooltip>

                                )}
                                renderTrack={({props, children}) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: "6px",
                                            width: "100%",
                                            backgroundColor: "#ccc",
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <Tooltip title={getTooltipRange()}>
                                        <div
                                            {...props}
                                            key={props.key}
                                            style={{
                                                ...props.style,
                                                height: "42px",
                                                width: "20px",
                                                backgroundColor: "#999",
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            />

                    }

                </Flex>
                <Flex align={"center"} justify={"center"}
                      style={{
                          width: "100%",
                          position: "relative"
                      }}
                      wrap={"wrap"}
                      gap={15}>
                    <MyText strong>
                        Время:
                        <span> {getTooltipRange()}</span>
                    </MyText>
                </Flex>
                <Flex align={"center"} justify={"center"}
                      style={{
                          width: "100%",
                          position: "relative"
                      }}
                      wrap={"wrap"}
                      gap={15}>
                    <Segmented onChange={(value) => {
                        setMapType(value);
                        handleGetInterpolationData(startDate, endDate);
                    }} size={"large"} defaultValue={"Шум"} color={clrs.blue} options={['Шум', 'Вибрация']} />
                </Flex>


                <MapContainer center={[51.1065, 71.4255]} zoom={17} style={{
                    width: "100%",
                    height: "600px",
                    borderRadius: 15,
                    cursor: addingBuilding || addingSensorPoint || addingConstructArea || addingMonitoringZone || addingMonitoringPoint ? 'crosshair' : 'auto'
                }}>
                    <LayersControl position="topright">
                        <BaseLayer checked name={t('gis.layer.standard')}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </BaseLayer>
                        <BaseLayer name={t('gis.layer.osm')}>
                            <TileLayer
                                url="https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"
                            />
                        </BaseLayer>
                        <BaseLayer name={t('gis.layer.satellite')}>
                            <TileLayer
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </BaseLayer>
                    </LayersControl>
                    <MetersPerPixelControl/>
                    <BuildingAdder onAddPolygon={handleAddBuilding} adding={addingBuilding} polygons={
                        gis?.buildings.map((building) => {
                            return (
                                building.polygon.points.map(point => {
                                    return {
                                        lat: point.latitude,
                                        lng: point.longitude,
                                    }
                                })
                            )
                        })
                    } />
                    <SensorPointAdder onAddPoint={handleAddSensorPoint} adding={addingSensorPoint} polygons={
                        gis?.buildings.map((building) => {
                            return (
                                building.polygon.points.map(point => {
                                    return {
                                        lat: point.latitude,
                                        lng: point.longitude,
                                    }
                                })
                            )
                        })
                    } />
                    <MonitoringPointAdder onAddPoint={handleAddMonitoringPoint} adding={addingMonitoringPoint} polygons={
                        gis?.buildings.map((building) => {
                            return (
                                building.polygon.points.map(point => {
                                    return {
                                        lat: point.latitude,
                                        lng: point.longitude,
                                    }
                                })
                            )
                        })
                    } />
                    <ConstructAreaAdder onAddPolygon={handleAddConstructArea} adding={addingConstructArea} polygons={
                        gis?.buildings.map((building) => {
                            return (
                                building.polygon.points.map(point => {
                                    return {
                                        lat: point.latitude,
                                        lng: point.longitude,
                                    }
                                })
                            )
                        })
                    } />
                    <MonitoringZoneAdder onAddSquare={handleAddMonitoringZone} adding={addingMonitoringZone} polygons={
                        gis?.buildings.map((building) => {
                            return (
                                building.polygon.points.map(point => {
                                    return {
                                        lat: point.latitude,
                                        lng: point.longitude,
                                    }
                                })
                            )
                        })
                    }/>
                    {
                        gis?.monitoringZone &&
                        <Polygon interactive={false} positions={gis?.monitoringZone.polygon.points.map(point => {
                            return {
                                lat: point.latitude,
                                lng: point.longitude,
                            }
                        })} pathOptions={{
                            color: 'black',
                            fillOpacity: 0,
                            weight: 1
                        }} />
                    }
                    {
                        gis?.monitoringZone && currentInterpolation &&
                        <Grid corners={gis?.monitoringZone.polygon.points.map(point => {
                            return {
                                lat: parseFloat(point.latitude),
                                lng: parseFloat(point.longitude),
                            }
                        })} isEditing={isEdit} currentMonitoringPoint={currentMonitoringPoint} setCurrentMonitoringPoint={setCurrentMonitoringPoint} setWeight={setCurrentMonitoringPointWeight} points={currentInterpolation?.items.map((interpolationItem) => {
                            if (interpolationItem.point) {
                                return {
                                    "lat": interpolationItem.point.latitude,
                                    "lng": interpolationItem.point.longitude,
                                    "weight": interpolationItem.lbj
                                }
                            }
                            return {
                                "lat": interpolationItem.sensor.point.latitude,
                                "lng": interpolationItem.sensor.point.longitude,
                                "weight": interpolationItem.lbj
                            }
                        })}/>
                    }
                    {
                        gis?.buildings.map((building, i) => {
                            return (
                                <Polygon key={new Date() + i} positions={building.polygon.points.map(point => {
                                    return {
                                        lat: point.latitude,
                                        lng: point.longitude,
                                    }
                                })} pathOptions={{ color: clrs.whiterBlacker }} />
                            )
                        })
                    }
                    {
                        gis?.constructArea &&
                        <Polygon positions={gis?.constructArea.polygon.points.map(point => {
                            return {
                                lat: point.latitude,
                                lng: point.longitude,
                            }
                        })} pathOptions={{ color: clrs.yellow }} />
                    }
                    {
                        tempSensorPoint &&
                        <DraggableMarker point={tempSensorPoint} handleSaveSensorPoint={handleSaveSensorPoint}/>
                    }
                    {
                        gis?.sensors.map((sensor, i) => {
                            return (
                                <Marker
                                    position={{
                                        lat: sensor.point.latitude,
                                        lng: sensor.point.longitude,
                                    }}
                                    key={new Date() + i + "sensorPoint"}
                                    icon={L.divIcon({
                                        className: 'custom-marker',
                                        html: '<div class="circle monitoringPoint"></div>',
                                        iconSize: [12, 12]
                                    })}
                                >
                                    <Popup minWidth={90}>
                                        <Flex align={"center"} justify={"center"} vertical style={{width: 200, position: "relative"}}>
                                            <MyText size={"small"} strong style={{margin: 0}}>
                                                <IntlMessage id={'gis.sensor'}/>
                                            </MyText>
                                            <MyText size={"small"} strong style={{margin: 0}}>
                                                <IntlMessage id={'name'}/>
                                                <span>: {sensor.name}</span>
                                            </MyText>
                                            <MyText size={"small"} strong style={{margin: 0}}>
                                                <IntlMessage id={'gis.sensor.alpha'}/>
                                                <span>: {sensor.alpha}</span>
                                            </MyText>
                                            <MyText size={"small"} strong style={{margin: 0}}>
                                                <span><strong>Lat:</strong> {sensor.point.latitude}</span>
                                            </MyText>
                                            <MyText size={"small"} strong style={{margin: 0}}>
                                                <span><strong>Long:</strong> {sensor.point.longitude}</span>
                                            </MyText>
                                        </Flex>
                                    </Popup>
                                </Marker>
                            )
                        })
                    }
                    {
                        gis?.monitoringPoints.map((monitoringPoint, i) => {
                            return (
                                <Marker
                                    position={{
                                        lat: monitoringPoint.point.latitude,
                                        lng: monitoringPoint.point.longitude,
                                    }}
                                    key={new Date() + i + "monitoringPoint"}
                                    icon={L.divIcon({
                                        className: 'custom-marker',
                                        html: '<div class="circle monitoringPoint2"></div>',
                                        iconSize: [12, 12]
                                    })}
                                    eventHandlers={{
                                        click: (e) => {
                                            console.log(monitoringPoint);
                                            setCurrentMonitoringPoint(monitoringPoint);
                                        },
                                    }}
                                >
                                    <Popup minWidth={90} >
                                        <Flex align={"center"} justify={"center"} vertical style={{width: 200, position: "relative"}}>
                                            <MyText size={"small"} strong style={{margin: 0}}>
                                                Мониторинг точка
                                            </MyText>
                                            <MyText size={"small"} strong style={{margin: 0}}>
                                                Уровень шума:
                                                <span> {((currentMonitoringPointWeight * 0.125045282) + 47.74861115).toFixed(2)} дБ</span>
                                            </MyText>
                                        </Flex>
                                    </Popup>
                                </Marker>
                            )
                        })
                    }
                    {
                        gis?.sensors.length > 0 &&
                        <Polygon interactive={false} positions={gis?.sensors.map((sensor, i) => {
                            return {
                                lat: sensor.point.latitude,
                                lng: sensor.point.longitude,
                            }
                        })} pathOptions={{ color: clrs.blue, weight: 0.2 }}/>
                    }
                    {/*{points.map((point, idx) => (*/}
                    {/*    <Marker key={idx} position={point} icon={L.divIcon({*/}
                    {/*        className: 'custom-marker',*/}
                    {/*        html: '<div class="circle"></div>',*/}
                    {/*        iconSize: [10, 10]*/}
                    {/*    })} />*/}
                    {/*))}*/}
                </MapContainer>

            </Flex>
            <Footer/>

            <style>
                {`
                    .custom-marker .circle {
                      width: 10px;
                      height: 10px;
                      background-color: red;
                      border-radius: 50%;
                    }
                    .custom-marker .circle.monitoringPoint {
                      background-image: linear-gradient(30deg, #0059ff, #4ca2f7) !important;
                      border: 2px solid black;
                      width: 12px;
                      height: 12px;
                    }
                    .custom-marker .circle.monitoringPoint2 {
                      background-image: linear-gradient(30deg, #8c8b8b, #8c8b8b) !important;
                      border: 2px solid black;
                      width: 12px;
                      height: 12px;
                    }
                    .custom-marker .circle.corner {
                      background-color: green;
                    }
                    .custom-marker .circle.temp-point {
                      background-color: yellow;
                    }
                `}
            </style>
        </Flex>
    );
};

const DraggableMarker = ({point, handleSaveSensorPoint}) => {
    const [position, setPosition] = useState(point)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng());
                }
            },
        }),
        [],
    )

    const [form] = Form.useForm();

    useEffect(() => {
        if (point) {
            form.resetFields();
            form.setFieldValue("lat", point.lat);
            form.setFieldValue("lng", point.lng);
        } else {
            setPosition(null);
            form.resetFields();
        }
    }, [point]);

    useEffect(() => {
        if (position) {
            form.setFieldValue("lat", position.lat);
            form.setFieldValue("lng", position.lng);
        }
    }, [position]);

    if (!point) {
        return '';
    }

    return (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}
            icon={L.divIcon({
                className: 'custom-marker',
                html: '<div class="circle monitoringPoint"></div>',
                iconSize: [12, 12]
            })}
        >
            <Popup minWidth={90}>
                <Flex align={"center"} justify={"center"} vertical style={{width: 200, position: "relative"}}>
                    <MyText size={"small"} strong>
                        <IntlMessage id={'gis.sensor'}/>
                    </MyText>
                    <Form
                        form={form}
                        className={classes.form}
                        style={{gap: 5}}
                    >
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="name">
                            <Input placeholder={'Название'}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="alpha">
                            <Input placeholder={'Альфа угол'}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="lat">
                            <Input placeholder={'latitude'} onChange={(v) => {
                                setPosition({
                                    ...position,
                                    lat: v.target.value
                                })
                            }}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="lng">
                            <Input placeholder={'longitude'} onChange={(v) => {
                                setPosition({
                                    ...position,
                                    lng: v.target.value
                                })
                            }}/>
                        </FormItem>
                    </Form>
                    <MyText onClick={() => {
                        form.validateFields()
                            .then(() => {
                                const lat = form.getFieldValue("lat");
                                const lng = form.getFieldValue("lng");
                                const alpha = form.getFieldValue("alpha");
                                const name = form.getFieldValue("name");
                                handleSaveSensorPoint(lat, lng, alpha, name);
                            })
                            .catch(() => {

                            })
                    }} size={"small"} hoverable strong style={{cursor: "pointer"}}>
                        <IntlMessage id={'save'}/>
                    </MyText>
                </Flex>
            </Popup>
        </Marker>
    )
}

const MetersPerPixelControl = () => {
    const [metersPerPixel, setMetersPerPixel] = useState(0);
    const map = useMap();

    // Function to calculate meters per pixel
    const calculateMetersPerPixel = () => {
        const centerLat = map.getCenter().lat;
        const zoomLevel = map.getZoom();

        // Formula to calculate meters per pixel
        const metersPerPixel = (156543.03392 * Math.cos((centerLat * Math.PI) / 180)) / Math.pow(2, zoomLevel);
        setMetersPerPixel(metersPerPixel.toFixed(4)); // Set value with two decimal places
    };

    useEffect(() => {
        // Call calculation when the map is moved or zoomed
        map.on('moveend zoomend', calculateMetersPerPixel);
        // Call initially to set the value
        calculateMetersPerPixel();

        // Cleanup on component unmount
        return () => {
            map.off('moveend zoomend', calculateMetersPerPixel);
        };
    }, [map]);

    return (
        <Flex
            style={{
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                padding: '5px',
                backgroundColor: 'white',
                width: 200,
                zIndex: 1000,
            }}
            align={"center"}
            justify={"center"}
            vertical
        >
            <div style={{top: -7, left: 0, width: 4, height: 7, backgroundColor: "white", zIndex: 10000, position: "absolute"}}></div>
            <div style={{top: -7, right: 0, width: 4, height: 7, backgroundColor: "white", zIndex: 10000, position: "absolute"}}></div>
            <MyText size={"small"}>{metersPerPixel} м/пиксель</MyText>
            <MyText size={"small"}>Дистанция: {(200 * metersPerPixel).toFixed(0)} м</MyText>
        </Flex>
    );
}

export default GisPage;