import React, {useState} from 'react';
import {Drawer, Dropdown, Flex, Image} from "antd";
import classes from './header.module.css';
import Logo from "../Logo/Logo";
import MyText from "../../../components/UI/Text/MyText";
import BlueButton from "../../../components/UI/Buttons/BlueButton";
import DefaultButton from "../../../components/UI/Buttons/DefaultButton";
import IntlMessage from "../../../components/IntlMessage/IntlMessage";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {clrs} from "../../../constants/colors";
import {useWindowSize} from "@uidotdev/usehooks";
import burger from '../../../images/burger-menu-5.svg';
import {useScroll} from "../../../components/Scroll/Scroll";

const Header = () => {
    const {user, isLoading} = useSelector(state => state.user);

    const size = useWindowSize();

    const [open, setOpen] = useState(false);

    const scroll = useScroll();

    const items = [
        {
            key: '1',
            label: (
                <Link to={'/profile'}>
                    <MyText size={"small"} style={{textAlign: "center"}}>
                        <IntlMessage id={'profile'}/>
                    </MyText>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to={'/my-projects'}>
                    <MyText size={"small"} style={{textAlign: "center"}}>
                        <IntlMessage id={'myProjects'}/>
                    </MyText>
                </Link>
            ),
        },
        {
            type: 'divider',
        },
        {
            key: '999',
            label: (
                <Link to={'/logout'}>
                    <MyText size={"small"} style={{textAlign: "center", color: clrs.red}}>
                        <IntlMessage id={'logout'}/>
                    </MyText>
                </Link>
            ),
        },
    ];

    if (size.width <= 768) {
        return (
            <>
                <div style={{height: 75}}></div>
                <Flex style={{width: "100%", height: 75, padding: 15}} className={classes.header} vertical align={"center"} justify={"center"}>
                    <Flex style={{width: "100%", maxWidth: 1440}} className={classes.headerInner} align={"center"} justify={"space-between"}>
                        <div className={classes.burger} onClick={() => {setOpen(true)}}>
                            <Image width={30} src={burger} preview={false}/>
                        </div>
                    </Flex>
                </Flex>
                <Drawer width={300} placement={'left'} open={open} onClose={() => {setOpen(false)}}>
                    <Flex style={{width: "100%"}} gap={35} vertical align={"center"} justify={"center"}>
                        <Link to={'/'} style={{color: "black"}}>
                            <Logo/>
                        </Link>
                        <Flex gap={30} align={"center"} vertical justify={"center"}>
                            <Link to={'/'} style={{color: "black"}}>
                                <MyText hoverable style={{cursor: "pointer", fontSize: "0.9rem"}}>
                                    <IntlMessage id={'home'}/>
                                </MyText>
                            </Link>
                            <Link to={'/'} style={{color: "black"}} onClick={() => {scroll('aboutUs')}}>
                                <MyText hoverable style={{cursor: "pointer", fontSize: "0.9rem"}}>
                                    <IntlMessage id={'aboutUs'}/>
                                </MyText>
                            </Link>
                            {user ?
                                <>
                                    <Link to={'/profile'} style={{color: "black"}}>
                                        <MyText hoverable size={"small"} style={{textAlign: "center"}}>
                                            <IntlMessage id={'profile'}/>
                                        </MyText>
                                    </Link>
                                    <Link to={'/my-projects'} style={{color: "black"}}>
                                        <MyText hoverable size={"small"} style={{textAlign: "center"}}>
                                            <IntlMessage id={'myProjects'}/>
                                        </MyText>
                                    </Link>
                                    <Link to={'/logout'}>
                                        <MyText size={"small"} style={{textAlign: "center", color: clrs.red}}>
                                            <IntlMessage id={'logout'}/>
                                        </MyText>
                                    </Link>
                                </>
                                :
                                <>
                                    <Link to={'/login'}>
                                        <BlueButton>
                                            <IntlMessage id={'login'}/>
                                        </BlueButton>
                                    </Link>
                                    <Link to={'/registration'}>
                                        <DefaultButton>
                                            <IntlMessage id={'registration'}/>
                                        </DefaultButton>
                                    </Link>
                                </>
                            }
                        </Flex>
                    </Flex>
                </Drawer>

            </>
        );
    }

    return (
        <>
            <div style={{height: 75}}></div>
            <Flex style={{width: "100%", height: 75, padding: 15}} className={classes.header} vertical align={"center"} justify={"center"}>
                <Flex style={{width: "100%", maxWidth: 1440}} className={classes.headerInner} align={"center"} justify={"space-between"}>
                    <Link to={'/'} style={{color: "black"}}>
                        <Logo/>
                    </Link>
                    <Flex gap={40} align={"center"} justify={"center"}>
                        <Flex gap={40} align={"center"} justify={"center"}>
                            <Link to={'/'} style={{color: "black"}}>
                                <MyText hoverable style={{cursor: "pointer", fontSize: "0.9rem"}}>
                                    <IntlMessage id={'home'}/>
                                </MyText>
                            </Link>
                            <Link to={'/'} style={{color: "black"}} onClick={() => {scroll('aboutUs')}}>
                                <MyText hoverable style={{cursor: "pointer", fontSize: "0.9rem"}}>
                                    <IntlMessage id={'aboutUs'}/>
                                </MyText>
                            </Link>
                            {/*<Link to={'/monitoring'} style={{color: "black"}}>*/}
                            {/*    <MyText hoverable style={{cursor: "pointer", fontSize: "0.9rem"}}>*/}
                            {/*        <IntlMessage id={'homePage.monitoring'}/>*/}
                            {/*    </MyText>*/}
                            {/*</Link>*/}
                        </Flex>
                        <Flex gap={15} align={"center"} justify={"center"}>
                            {user ?
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                    placement="bottom"
                                >
                                    <BlueButton>
                                        <IntlMessage id={'personalCabinet'}/>
                                    </BlueButton>
                                </Dropdown>
                                :
                                <>
                                    <Link to={'/login'}>
                                        <BlueButton>
                                            <IntlMessage id={'login'}/>
                                        </BlueButton>
                                    </Link>
                                    <Link to={'/registration'}>
                                        <DefaultButton>
                                            <IntlMessage id={'registration'}/>
                                        </DefaultButton>
                                    </Link>
                                </>
                            }

                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default Header;