const points = [
    {
        attributes: { TN_: 50 },
        geometry: { x: 120.8696333, y: 38.65953686 }
    },
    {
        attributes: { TN_: 45 },
        geometry: { x: 120.8697333, y: 38.65973686 }
    },
    {
        attributes: { TN_: 55 },
        geometry: { x: 120.8698333, y: 38.65993686 }
    },
    {
        attributes: { TN_: 60 },
        geometry: { x: 120.8699333, y: 38.66013686 }
    },
    {
        attributes: { TN_: 65 },
        geometry: { x: 120.8700333, y: 38.66033686 }
    },
    // Add more points as needed
];

export default points;
