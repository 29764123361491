import React, {useState} from 'react';
import {Flex, Form, Input, message} from "antd";
import Header from "../components/Header/Header";
import MyText from "../../components/UI/Text/MyText";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import Footer from "../components/Footer/Footer";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import classes from '../global_styles.module.css';
import FormItem from "antd/es/form/FormItem";
import {UsernameValidator} from "../../utils/UsernameValidator";
import {PasswordValidator} from "../../utils/PasswordValidator";
import {KeyOutlined, SolutionOutlined, UserOutlined} from "@ant-design/icons";
import PhoneIcon from "../../components/icons/PhoneIcon";
import BlueButton from "../../components/UI/Buttons/BlueButton";
import {useWindowSize} from "@uidotdev/usehooks";
import {useTranslation} from "react-i18next";
import AuthService from "../../services/AuthService";
const RegistrationPage = () => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const username = Form.useWatch('username', form);
    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);
    const name = Form.useWatch('name', form);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const size = useWindowSize();
    const {user, isLoading} = useSelector(state => state.user);

    const [loading, setLoading] = useState(false);

    function handleOnSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setLoading(true);
                await AuthService.register(
                    username,
                    password,
                    name
                )
                    .then(async (result) => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        navigate('/login')
                    })
                    .catch((result) => {
                        console.log(result)
                        message.destroy();
                        if (result.response.data === 'User already exists') {
                            message.error(<IntlMessage id={'userAlreadyExists'}/>, 5);
                        } else {
                            message.error(<IntlMessage id={'error'}/>, 5);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            })
            .catch(() => {

            })
    }


    if (user !== null && !isLoading) {
        navigate('/profile');
    }

    return (
        <Flex style={{width: "100%", position: "relative", paddingLeft: 15, paddingRight: 15}} vertical align={"center"} justify={"flex-start"} gap={50}>
            <Header/>
            <Flex style={{width: "100%", maxWidth: 1440, minHeight: "60vh"}} vertical align={"center"} justify={"center"} gap={50}>
                <MyText uppercase strong size={"large"}>
                    <IntlMessage id={'registration'}/>
                </MyText>
                <Flex style={{width: "100%", position: "relative", maxWidth: 500}}>
                    <Form form={form} style={{maxWidth: 500}} className={classes.form}>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (UsernameValidator.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('usernameRules')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="username">
                            <Input disabled={loading} onInput={e => e.target.value = e.target.value.toLowerCase()} placeholder={t('username')} prefix={<UserOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (PasswordValidator.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('passwordRules')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="password">
                            <Input.Password disabled={loading} placeholder={t('password')} prefix={<KeyOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('confirmPasswordError')));
                                },
                            }),
                        ]} className={classes.formInputBlock} name="confirmPassword">
                            <Input.Password disabled={loading} placeholder={t('confirmPassword')} prefix={<KeyOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="name">
                            <Input disabled={loading} placeholder={t('firstName')} prefix={<SolutionOutlined style={{marginRight: 5}}/>} rootClassName={classes.formInput} />
                        </FormItem>
                        <BlueButton disabled={loading} onClick={handleOnSubmit}>
                            <IntlMessage id={'registrationPage.register'}/>
                        </BlueButton>
                    </Form>
                </Flex>
                <Link to={'/login'}>
                    <MyText size={"small"}>
                        <IntlMessage id={'i have an account'}/>
                    </MyText>
                </Link>

            </Flex>

            <Footer/>
        </Flex>
    );
};

export default RegistrationPage;