import {disableReactDevTools} from '@fvilers/disable-react-devtools';
import {REACT_APP_NODE_ENV} from "./constants/env";
import './lang';
import {ScrollProvider} from "./components/Scroll/Scroll";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import BackTop from "./pages/components/BackTop/BackTop";
import LanguageButton from "./pages/components/Lan/LanguageButton";
import HomePage from "./pages/Home/HomePage";
import {getUserByToken} from "./store/slices/userSlice";
import FullLoading from "./components/LoadingComponents/FullLoading";
import MonitoringPage from "./pages/Monitoring/MonitoringPage";
import LoginPage from "./pages/Login/LoginPage";
import RegistrationPage from "./pages/Registration/RegistrationPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import KrigingMap from "./pages/Monitoring/KrigingMap";
import Logout from "./pages/Logout/Logout";
import MyProjectsPage from "./pages/MyProjects/MyProjectsPage";
import GisPage from "./pages/Gis/GisPage";

if (REACT_APP_NODE_ENV === 'production') {
    disableReactDevTools();
}

function App() {
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
            dispatch(getUserByToken());
    }, []);

    if (isLoading) {
        return <FullLoading/>;
    }

    return (
        <ScrollProvider>
            <BackTop/>
            {/*<LanguageButton/>*/}
            <Router>
                <Routes>
                    <Route path='/' element={<HomePage/>}/>
                    <Route path='/login' element={<LoginPage/>}/>
                    <Route path='/logout' element={<Logout/>}/>
                    <Route path='/registration' element={<RegistrationPage/>}/>
                    <Route path='/profile' element={<ProfilePage/>}/>
                    <Route path='/my-projects' element={<MyProjectsPage/>}/>
                    <Route path='/gis-project/:id' element={<GisPage/>}/>
                    <Route path='/monitoring' element={<MonitoringPage/>}/>
                    <Route path='/kriging' element={<KrigingMap/>}/>
                </Routes>
            </Router>
        </ScrollProvider>
    );
}

export default App;
