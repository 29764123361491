import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const AuthService = {};
AuthService.login = async function (username, password) {
    let data = new FormData();
    data.append("username", username);
    data.append("password", password);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/auth/login",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

AuthService.refreshToken = async function (refreshToken) {
    let data = new FormData();
    data.append("refreshToken", refreshToken);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/auth/refresh",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

AuthService.getUserByToken = async () => {
    let data = new FormData();
    const token = localStorage.getItem(ACCESS_TOKEN);
    data.append("token", token);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/auth/get/user/token",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

AuthService.register = async function (username, password, name) {
    let data = new FormData();
    data.append("username", username);
    data.append("password", password);
    data.append("name", name);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/auth/register",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

export default AuthService;