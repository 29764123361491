import React from 'react';
import {Flex} from "antd";

const Logo = () => {

    const style = {
        fontWeight: "bold",
        fontSize: "1.5rem",
        cursor: "pointer"
    }

    return (
        <Flex style={{ position: "relative"}} align={"center"} justify={"center"}>
            <span style={style}>SVAM KZ</span>
        </Flex>
    );
};

export default Logo;