import React from 'react';
import {Button} from "antd";
import classes from "./buttons.module.css";

const BlueButton = (props) => {
    return (
        <Button {...props} className={classes.blueBtn}>
            <span>{props.children}</span>
        </Button>
    );
};

BlueButton.propTypes = {
    ...Button.propTypes, // Use Ant Design Button propTypes
};

export default BlueButton;