import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import kriging from './kriging';
import points from './points';
import world from './world';

const KrigingMap = () => {
    const mapRef = useRef(null);

    useEffect(() => {
        const map = L.map(mapRef.current, {
            center: [38.65953686, 120.8696333],
            zoom: 9
        });

        // Add OpenStreetMap tiles
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        // Generate scope boundary from world data
        const positions = world[0].map(point => [point[1], point[0]]);
        const scope = L.polyline(positions, { color: 'red' }).addTo(map);
        map.fitBounds(scope.getBounds());

        // Define boundary limits
        const xlim = [scope.getBounds().getSouthWest().lng, scope.getBounds().getNorthEast().lng];
        const ylim = [scope.getBounds().getSouthWest().lat, scope.getBounds().getNorthEast().lat];

        // Perform Kriging interpolation
        const canvas = document.createElement('canvas');
        canvas.width = 1000;
        canvas.height = 1000;
        const n = points.length;
        const t = []; // values
        const x = []; // longitudes
        const y = []; // latitudes
        for (let i = 0; i < n; i++) {
            t.push(points[i].attributes.TN_);
            x.push(points[i].geometry.x);
            y.push(points[i].geometry.y);
            L.circle([y[i], x[i]], { radius: 1 }).addTo(map);
        }

        // Train the variogram
        const variogram = kriging.train(t, x, y, 'spherical', 0, 100);

        // Create the grid
        const grid = kriging.grid(world, variogram, (ylim[1] - ylim[0]) / 150);

        const colors = ['#006837', '#1a9850', '#66bd63', '#a6d96a', '#d9ef8b', '#ffffbf', '#fee08b', '#fdae61', '#f46d43', '#d73027', '#a50026'];
        // Plot the grid on the canvas
        kriging.plot(canvas, grid, [xlim[0], xlim[1]], [ylim[0], ylim[1]], colors);

        // Convert canvas to image URL
        const imageUrl = canvas.toDataURL('image/png');
        const imageBounds = [[ylim[0], xlim[0]], [ylim[1], xlim[1]]];
        L.imageOverlay(imageUrl, imageBounds, { opacity: 0.8 }).addTo(map);

        // Cleanup on unmount
        return () => {
            map.remove();
        };
    }, []);

    return (
        <div style={{width: '100vw', height: '100vh'}}>
            <div ref={mapRef} style={{width: '100%', height: '100%'}}/>
        </div>
    );
};

export default KrigingMap;
